import React, { useContext } from 'react';
import { IconSvg, Button } from '@jobandtalent/design-system';
import I18n from 'lib/i18n';

// Stylesheets
import './job-download-on-mobile.component.css.less';
import { JobOpportunityContext } from 'Jobfeed/context/JobOpeningContext';
import { flush, track } from '@amplitude/analytics-browser';

const JobDownloadOnMobile = () => {
  const jobOpportunityContext = useContext(JobOpportunityContext);

  const goToTheApp = () => {
    track('download_app_requested', {
      store: 'unknown'
    });
    flush();

    window.location.href = jobOpportunityContext.deeplink;
  };

  return (
    <>
      <div
        className="JobDownloadOnMobile__backdrop"
        onClick={jobOpportunityContext?.hideDownloadOnMobile}
        role="button"
        tabIndex={0}
        onKeyDown={event => {
          if (event.key === 'Escape' && jobOpportunityContext) {
            jobOpportunityContext.hideDownloadOnMobile();
          }
        }}
      />
      <div className="JobDownloadOnMobile">
        <button
          type="button"
          className="JobDownloadOnMobile__close"
          onClick={jobOpportunityContext?.hideDownloadOnMobile}
        >
          <IconSvg className="ds-org-modal-close-icon" icon="cross" />
        </button>
        <div className="JobDownloadOnMobile__title">
          {I18n.t('jobfeed.apply_modal_mobile.title')}
        </div>
        <div className="JobDownloadOnMobile__content">
          <div className="JobDownloadOnMobile__text">
            {I18n.t('jobfeed.apply_modal_mobile.description')}
          </div>
          <div className="JobDownloadOnMobile__buttons">
            <Button onSelect={goToTheApp}>{I18n.t('jobfeed.apply_modal_mobile.button')}</Button>
          </div>
        </div>
      </div>
    </>
  );
};

JobDownloadOnMobile.propTypes = {};
JobDownloadOnMobile.defaultProps = {};

export default JobDownloadOnMobile;
