import React from 'react';
import PropTypes from 'prop-types';
import { Router, Switch, Route, Redirect } from 'react-router-dom';

// Store
import { useStore } from 'Jobfeed/store/hook';

// Components
import useCommonPaths from 'Common/components/router/paths';
import useJobfeedPaths from 'Common/components/router/jobfeed/paths';
import JobfeedRoutes from 'Common/components/router/jobfeed/routes';

const commonPaths = useCommonPaths();
const jobfeedPaths = useJobfeedPaths();

const AuthRouter = ({ basename, history }) => {
  const store = useStore();
  const countryCode = store.getCountryCode();
  const moduleRoutes = () => {
    switch (basename) {
      case jobfeedPaths.basename():
        return <JobfeedRoutes />;
      default:
        return <></>;
    }
  };

  const moduleRootPath = () => {
    switch (basename) {
      case jobfeedPaths.basename():
        return jobfeedPaths.defaultJobs(countryCode.toLowerCase());
      default:
        return <></>;
    }
  };

  return (
    <Router history={history}>
      <Switch>
        {/* Default path */}
        <Route path={commonPaths.root()} exact render={() => <Redirect to={moduleRootPath()} />} />
        {/* Current module paths based on basename */}
        {moduleRoutes()}
      </Switch>
    </Router>
  );
};

AuthRouter.propTypes = {
  basename: PropTypes.string,
  history: PropTypes.object.isRequired
};

AuthRouter.defaultProps = {
  basename: jobfeedPaths.basename()
};

export default AuthRouter;
