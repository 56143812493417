import ApiClient from 'Common/services/ApiClient';
import { getUTMData, getUTMSource } from './tracking.service';

class JobOpportunitiesService {
  endpoint = '/job_opportunities';

  constructor() {
    this.client = new ApiClient({
      defaultHeaders: { 'X-Organization': 'Jobandtalent' }
    });
  }

  getJobOpportunities(params) {
    const endpoint = '/job_opportunities';
    const { regions, job_functions, ...rest } = params;
    const searchParams = new URLSearchParams(rest);

    if (regions?.length) {
      regions.forEach(regionSlug => {
        searchParams.append('provinces[]', regionSlug);
      });
    }

    if (job_functions?.length) {
      job_functions.forEach(jobFunctionSlug => {
        searchParams.append('job_functions[]', jobFunctionSlug);
      });
    }

    return this.client.get(`${endpoint}?${searchParams.toString()}`);
  }

  getJobOpportunity(job_opening_id) {
    const endpoint = `/job_opportunities`;
    return this.client.get(`${endpoint}/${job_opening_id}`);
  }

  getRegions(params) {
    const endpoint = '/regions';
    const searchParams = new URLSearchParams(params).toString();

    return this.client.get(`${endpoint}?${searchParams}`);
  }

  getJobFunctions(params) {
    const endpoint = '/job_functions';
    const searchParams = new URLSearchParams(params).toString();

    return this.client.get(`${endpoint}?${searchParams}`);
  }

  getDeepLink(job_opportunity_id, csrfToken, params) {
    const { utm_campaign, utm_source } = getUTMData();
    const deeplinkGenerateLink = `/api-external/v1/jobfeed/job_opportunities/${job_opportunity_id}/deeplink/create`;
    const endpoint = new URL(deeplinkGenerateLink, window.location.origin);

    if (utm_campaign) {
      endpoint.searchParams.append('utm[utm_campaign]', utm_campaign);
    }

    if (utm_source) {
      endpoint.searchParams.append('utm[utm_source]', utm_source);
    } else {
      endpoint.searchParams.append('utm[utm_source]', getUTMSource());
    }

    endpoint.searchParams.append('utm[utm_medium]', 'web_job_feed');

    if (params) {
      Object.keys(params).forEach(key => {
        endpoint.searchParams.append(key, params[key]);
      });
    }

    return this.client.post(endpoint.toString(), {}, csrfToken);
  }
}

export default new JobOpportunitiesService();
