import React, { createContext, useState, useContext } from 'react';
import { createConsumer } from '@rails/actioncable';
import PropTypes from 'prop-types';

const cableUrl = () => {
  if (typeof document === 'undefined') return '';

  return document.head.querySelector('meta[name="action-cable-url"]')?.content || '';
};

const cableInitialState = {
  url: cableUrl(),
  subscription: null,
  subscriptionStatus: false
};

const ActionCableContext = createContext();

const ActionCableProvider = ({ children }) => {
  const [cableState, setCableState] = useState(cableInitialState);

  return (
    <ActionCableContext.Provider value={[cableState, setCableState]}>
      {children}
    </ActionCableContext.Provider>
  );
};

const useActionCable = () => {
  if (typeof document === 'undefined') return null;

  const [cableState, setCableState] = useContext(ActionCableContext);

  if (cableState.url === '') {
    return null;
  }

  const cable = createConsumer(cableState.url);

  const actionCableUnsubscribe = () => {
    if (cable.subscription != null) cableState.subscription.unsubscribe();

    setCableState({
      ...cableState,
      subscription: null,
      subscriptionStatus: false
    });
  };

  const actionCableSubscribe = ({ channel, id, callback }) => {
    const subscription = cable.subscriptions.create(
      { channel, id },
      {
        received: ({ action, data }) => {
          callback({ action, data });
        },
        disconnected: actionCableUnsubscribe
      }
    );

    setCableState({
      ...cableState,
      subscription,
      subscriptionStatus: true
    });
  };

  return {
    ...cableState,
    setCableState,
    cable,
    actionCableSubscribe,
    actionCableUnsubscribe
  };
};

ActionCableProvider.propTypes = {
  children: PropTypes.node
};

ActionCableProvider.defaultProps = {
  children: null
};

export { ActionCableProvider, ActionCableContext, useActionCable };
