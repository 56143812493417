import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import {
  ADD_SELECTED_REGION,
  DELETE_SELECTED_REGION,
  UPDATE_SELECTED_REGIONS,
  ADD_SELECTED_JOB_FUNCTION,
  DELETE_SELECTED_JOB_FUNCTION,
  UPDATE_SELECTED_JOB_FUNCTIONS,
  RESET_FILTERS,
  CHANGE_COUNTRY,
  CHANGE_SORT
} from 'Jobfeed/store/actions_names';
import fromActions from 'Jobfeed/store/actions';
import JobOpportunity from 'Jobfeed/models/job-opening.model';

export const useStore = () => {
  const dispatch = useDispatch();
  const currentUrl = useSelector(state => state.router?.location.pathname);
  const currentLocale = useSelector(state => state.jobfeed.countryCode?.toLowerCase());
  const addLocaleToParams = (object = {}) => {
    const params = object;
    if (currentLocale) {
      params.locale = currentLocale;
    }
    return params;
  };

  const jobOpportunities = useSelector(state => state.jobfeed.jobOpportunities);
  const jobOpportunity = useSelector(state => state.jobfeed.jobOpportunity);

  return {
    getLandingsUrl: () => {
      return useSelector(state => state.jobfeed.landingsUrl);
    },
    getCountryCode: () => {
      return useSelector(state => state.jobfeed.countryCode);
    },
    getRegions: () => {
      return useSelector(state => state.jobfeed.regions);
    },
    getSelectedRegions: () => {
      return useSelector(state => state.jobfeed.selectedRegions);
    },
    getJobFunctions: () => {
      return useSelector(state => state.jobfeed.jobFunctions);
    },
    getSelectedJobFunctions: () => {
      return useSelector(state => state.jobfeed.selectedJobFunctions);
    },
    getJobOpportunities: () => {
      return jobOpportunities;
    },
    getJobOpportunity: () => {
      return useSelector(state => state.jobfeed.jobOpportunity);
    },
    getTotalNumber: () => {
      return useSelector(state => state.jobfeed.totalNumber);
    },
    getPerPage: () => {
      return useSelector(state => state.jobfeed.perPage);
    },
    getFocusOn: () => {
      return useSelector(state => state.jobfeed.focusOn);
    },
    getGoogleMapsKey: () => {
      return useSelector(state => state.jobfeed.googleMapsApiKeyPublic);
    },
    getSortBy: () => {
      return useSelector(state => state.jobfeed.sortBy);
    },
    getSortDir: () => {
      return useSelector(state => state.jobfeed.sortDir);
    },
    disableFocusOn: () => {
      dispatch(fromActions.disableFocusOn());
    },
    isJobDetailLoading: () => {
      return useSelector(state => state.jobfeed.jobDetail.loading);
    },
    isJobDetailFetchingSuccessful: () => {
      return useSelector(state => state.jobfeed.jobDetail.fetchingSuccessful);
    },
    isJobListLoading: () => {
      return useSelector(state => state.jobfeed.jobList.loading);
    },
    isJobListFetchingSuccessful: () => {
      return useSelector(state => state.jobfeed.jobList.fetchingSuccessful);
    },
    fetchJobList: options => {
      const params = addLocaleToParams(options);
      dispatch(fromActions.fetchJobList(params));
    },
    fetchJobDetail: ({ job_opening_id }) => {
      if (jobOpportunity) {
        dispatch(
          fromActions.fetchJobDetailSuccess({ jobOpportunity: new JobOpportunity(jobOpportunity) })
        );
      } else {
        dispatch(fromActions.fetchJobDetail(job_opening_id));
      }
    },
    setJobDetail: selectedJobOpportunity => {
      dispatch(fromActions.fetchJobDetailSuccess({ jobOpportunity: selectedJobOpportunity }));
    },
    goToJobDetail: job => {
      const countryCode = job.geodatum.country.countryCode.toLowerCase();
      const regionSlug = job.geodatum.subdivision.slug;

      let jobRoute = `/${countryCode}/${job.jobFunctionSlug}/${regionSlug}/${job.slug}`;

      if (currentLocale) {
        jobRoute = `${jobRoute}?locale=${currentLocale}`;
      }

      dispatch(push(jobRoute));
    },
    fetchJobFunctions: options => {
      const params = addLocaleToParams(options);
      dispatch(fromActions.fetchJobFunctions(params));
    },
    fetchRegions: options => {
      const params = addLocaleToParams(options);
      dispatch(fromActions.fetchRegions(params));
    },
    addSelectedRegion: region => {
      dispatch(
        fromActions.updateFilter({
          updateType: ADD_SELECTED_REGION,
          payload: region
        })
      );
    },
    deleteSelectedRegion: region => {
      dispatch(
        fromActions.updateFilter({
          updateType: DELETE_SELECTED_REGION,
          payload: region
        })
      );
    },
    updateSelectedRegions: selectedRegions => {
      dispatch(
        fromActions.updateFilter({
          updateType: UPDATE_SELECTED_REGIONS,
          payload: selectedRegions
        })
      );
    },
    addSelectedJobFunction: jobFunction => {
      dispatch(
        fromActions.updateFilter({
          updateType: ADD_SELECTED_JOB_FUNCTION,
          payload: jobFunction
        })
      );
    },
    deleteSelectedJobFunction: jobFunction => {
      dispatch(
        fromActions.updateFilter({
          updateType: DELETE_SELECTED_JOB_FUNCTION,
          payload: jobFunction
        })
      );
    },
    updateSelectedJobFunctions: selectedJobFunctions => {
      dispatch(
        fromActions.updateFilter({
          updateType: UPDATE_SELECTED_JOB_FUNCTIONS,
          payload: selectedJobFunctions
        })
      );
    },
    resetFilters: () => {
      dispatch(fromActions.updateFilter({ updateType: RESET_FILTERS, payload: null }));
    },
    changeCountry: countryCode => {
      dispatch(
        fromActions.updateFilter({
          updateType: CHANGE_COUNTRY,
          payload: countryCode
        })
      );
    },
    changePage: page => {
      const params = addLocaleToParams({ page });
      dispatch(push(`${currentUrl}?${new URLSearchParams(params).toString()}`));
    },
    setSort: (sortBy, sortDir) => {
      dispatch(
        fromActions.updateFilter({
          updateType: CHANGE_SORT,
          payload: {
            sortBy,
            sortDir
          }
        })
      );
    },
    getErrors: () => {
      return useSelector(state => state.jobfeed.errors);
    },
    getCandidate: () => {
      return useSelector(state => state.jobfeed.candidate);
    },
    applyToJob: (options = {}) => {
      dispatch(fromActions.applyToJob(options));
    },
    fetchGeolocationMapUrlSignature: url => {
      dispatch(fromActions.fetchGeolocationMapUrlSignature(url));
    },
    updateCompliance: compliant => {
      dispatch(fromActions.updateCompliance(compliant));
    },
    getCsrfToken: () => {
      return useSelector(state => state.csrfToken);
    },
    getQueryParams: () => {
      return useSelector(state => new URLSearchParams(state.router?.location.search));
    }
  };
};

export default useStore;
