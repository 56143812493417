import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

// Stylesheets
import './checkbox.component.css.less';

const Checkbox = ({ text, value, name, checked, onChange, className }) => {
  return (
    <label className={classnames('Checkbox', className)}>
      <input checked={checked} type="checkbox" value={value} name={name} onChange={onChange} />
      <span className="Checkbox__label">{text}</span>
    </label>
  );
};

Checkbox.propTypes = {
  text: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string
};

Checkbox.defaultProps = {
  text: '',
  value: '',
  name: 'checkbox',
  checked: false,
  onChange: () => undefined,
  className: ''
};

export default Checkbox;
