import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './wrap.component.css.less';

const Wrap = ({ children, className }) => (
  <div className={classnames('Wrap', className)}>{children}</div>
);

Wrap.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

Wrap.defaultProps = {
  className: ''
};

export default Wrap;
