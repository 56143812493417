import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { CookieManager, EventBus } from '@jobandtalent/cookie-consent';

import './cookie-consent.component.css.less';

const STATUS = {
  active: 'active',
  disable: 'disabled',
  hidden: 'hidden'
};

const HANDLERS = {
  analytical: document.analytical,
  advertising: document.advertising
};

const CookieConsent = ({ cookieName }) => {
  const getLegacyCookieName = name => {
    return `${name}-legacy`;
  };

  /**
   * Returns the value of the consent cookie
   * Retrieves the regular value first and if not found the legacy one according
   * to: https://web.dev/samesite-cookie-recipes/#handling-incompatible-clients
   */
  const getCookieValue = () => {
    let cookieValue = Cookies.get(cookieName);

    // if the cookieValue is undefined check for the legacy cookie
    if (cookieValue === undefined) {
      cookieValue = Cookies.get(getLegacyCookieName(cookieName));
    }
    return cookieValue;
  };

  const [state, dispatch] = useReducer(
    // eslint-disable-next-line no-shadow
    (state, action) => {
      switch (action.type) {
        case 'status':
          return {
            ...state,
            status: action.status
          };

        default:
          return state;
      }
    },
    {
      status: getCookieValue() == null ? STATUS.active : STATUS.hidden
    }
  );

  useEffect(() => {
    const handleCookie = ({ detail }) => {
      const { cookies } = detail;
      cookies.forEach(c => {
        const fn = HANDLERS[c];
        if (fn != null) {
          fn.call();
        }
      });

      dispatch({ type: 'status', status: STATUS.hidden });
    };

    EventBus.on('onCookiesConsent', handleCookie);

    return () => {
      EventBus.off('onCookiesConsent', handleCookie);
    };
  }, [dispatch]);

  const { status } = state;

  return status !== STATUS.hidden ? <CookieManager /> : null;
};

CookieConsent.propTypes = {
  cookieName: PropTypes.string.isRequired
};

export default CookieConsent;
