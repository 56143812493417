import classnames from 'classnames';
import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import I18n from 'lib/i18n';
import { track } from '@amplitude/analytics-browser';

import { IconSvg } from '@jobandtalent/design-system';
import { GoogleMap, Marker } from '@react-google-maps/api';

// Components
import { useStore } from 'Jobfeed/store/hook';

import pinSvg from 'Images/pin_slim.svg';
import { GlobalContext } from 'Jobfeed/context/GlobalContext';

// Stylesheets
import './job-detail.component.css.less';

const getMonthShortName = (countryCode, monthNo) => {
  const date = new Date();
  date.setMonth(monthNo);

  const month = date.toLocaleString(countryCode, { month: 'short' });

  return month.charAt(0).toUpperCase() + month.slice(1);
};

const getWorkplaceAddress = jobOpportunity => {
  let workplaceAddress = '';

  if (jobOpportunity?.geodatum) {
    const addressParts = [];

    if (jobOpportunity.geodatum.street) {
      addressParts.push(jobOpportunity.geodatum.street);
    }

    if (jobOpportunity.geodatum.streetNumber) {
      addressParts.push(jobOpportunity.geodatum.streetNumber);
    }

    if (jobOpportunity.geodatum.postalCode) {
      let addressPart = jobOpportunity.geodatum.postalCode;

      if (jobOpportunity.geodatum.locality) {
        addressPart = `${addressPart} ${jobOpportunity.geodatum.locality}`;
      }

      addressParts.push(addressPart);
    }

    if (jobOpportunity.geodatum?.country?.verboseName) {
      addressParts.push(jobOpportunity.geodatum.country.verboseName);
    }

    workplaceAddress = addressParts.join(', ');
  }

  return workplaceAddress;
};

const getCompanyName = jobOpportunity => {
  let companyName = I18n.t('jobfeed.job_detail.company_hidden', {
    location: jobOpportunity?.geodatum?.formattedHiddenLocation
  });

  if (!jobOpportunity.hideCompanyInfo) {
    companyName = I18n.t('jobfeed.job_detail.where', {
      company: jobOpportunity.companyName,
      location: jobOpportunity.formattedLocation
    });
  }

  return companyName;
};

const JobDetail = ({ jobOpportunity }) => {
  const store = useStore();
  const countryCode = store.getCountryCode();
  const [salarySectionOpen, setSalarySectionOpen] = useState(false);
  const globalContext = useContext(GlobalContext);
  const containerStyle = {
    width: '100%',
    height: '256px'
  };

  const center = {
    lat: jobOpportunity?.geodatum?.coordinates?.lat,
    lng: jobOpportunity?.geodatum?.coordinates?.lon
  };

  const toggleSalarySection = () => {
    setSalarySectionOpen(!salarySectionOpen);

    track('job_detail_component_interacted', {
      job_opportunity_id: jobOpportunity.id,
      component: 'salary',
      action: salarySectionOpen ? '' : 'open',
      type: '',
      value: null
    });
  };

  const startDate = new Date(jobOpportunity.startDate);
  const otherHours = jobOpportunity.payRate?.otherHours;
  const variablePay = jobOpportunity.payRate?.variablePay;
  const expenses = jobOpportunity.payRate?.expenses;
  const hasExtraPay = otherHours?.length > 0 || variablePay?.length > 0 || expenses?.length > 0;

  return (
    <>
      <div className="JobDetail__main-content">
        <h2 className="JobDetail__title">{jobOpportunity.title}</h2>
        <div className="JobDetail__where">{getCompanyName(jobOpportunity)}</div>
        <div className="JobDetail__when">
          {I18n.t('jobfeed.job_detail.when', {
            day: startDate.getDate(),
            month: getMonthShortName(countryCode, startDate.getMonth())
          })}
        </div>
        <div className="JobDetail__section-title">{I18n.t('jobfeed.job_detail.pay_title')}</div>
        <div
          className={classnames('JobDetail__section', {
            'JobDetail__section--open': salarySectionOpen
          })}
        >
          <div
            role="button"
            tabIndex={0}
            className={classnames('JobDetail__salary-title', {
              'JobDetail__salary-title--enbled': hasExtraPay
            })}
            onClick={hasExtraPay ? toggleSalarySection : undefined}
            onKeyDown={event => {
              if (hasExtraPay && (event.key === 'Space' || event.key === 'Enter')) {
                toggleSalarySection();
              }
            }}
          >
            <div>
              <div className="JobDetail__salary-base">{jobOpportunity.salary}</div>
              {hasExtraPay && (
                <div className="JobDetail__salary-extras">
                  {I18n.t('jobfeed.job_detail.extras_title')}
                </div>
              )}
            </div>
            {hasExtraPay && (
              <IconSvg className="JobDetail__section-toggle-icon" icon="chevron-down" />
            )}
          </div>
          <div
            className={classnames('JobDetail__section-content', {
              'JobDetail__section-content--open': salarySectionOpen
            })}
          >
            {otherHours?.length > 0 && (
              <>
                <div className="JobDetail__section-content-title">
                  {I18n.t('jobfeed.job_detail.other_hours_title')}
                </div>
                <ul className="JobDetail__section-content-details">
                  {otherHours.map(item => {
                    return <li key={item}>{item}</li>;
                  })}
                </ul>
              </>
            )}
            {variablePay?.length > 0 && (
              <>
                <div className="JobDetail__section-content-title">
                  {I18n.t('jobfeed.job_detail.variable_pay_title')}
                </div>
                <ul className="JobDetail__section-content-details">
                  {variablePay.map(item => {
                    return <li key={item}>{item}</li>;
                  })}
                </ul>
              </>
            )}
            {expenses?.length > 0 && (
              <>
                <div className="JobDetail__section-content-title">
                  {I18n.t('jobfeed.job_detail.expenses_title')}
                </div>
                <ul className="JobDetail__section-content-details">
                  {expenses.map(item => {
                    return <li key={item}>{item}</li>;
                  })}
                </ul>
              </>
            )}
          </div>
        </div>
        {/* TODO: to be implemented when the data in API is there: https://jobandtalent.atlassian.net/browse/WRK-2434 */}
        {/* <div className="JobDetail__section-title">When you’ll work</div>
      <div className="JobDetail__section">
        <div>Part-time, up to 35 hours</div>
        <div>Morning, afternoon and night</div>
        <div>Days you'll work</div>
        <div>Weekdays and weekends.</div>
        <div>More information</div>
        <div>Rotative shift: week 1 mornings (7AM-14PM), week 2 afternoons (14PM-21PM), week 3 nights (21PM-5AM) </div>
      </div> */}
        <div className="JobDetail__section-title">
          {I18n.t('jobfeed.job_detail.description_title')}
        </div>
        <div className="JobDetail__section">
          <div className="JobDetail__description">{jobOpportunity.description}</div>
        </div>
        <div className="JobDetail__section-title">
          {I18n.t('jobfeed.job_detail.job_function_title')}
        </div>
        <div className="JobDetail__section">
          <ul className="JobDetail__responsibilities">
            {jobOpportunity?.responsibilities?.map(item => {
              return (
                <li className="JobDetail__responsibility" key={item}>
                  {item}
                </li>
              );
            })}
          </ul>
        </div>

        {globalContext?.googleMapsLoaded && !jobOpportunity.hideCompanyInfo ? (
          <>
            <div className="JobDetail__section-title">{I18n.t('jobfeed.job_detail.map_title')}</div>
            <div className="JobDetail__section JobDetail__section--map">
              <div className="JobDetail__address">
                <img src={pinSvg} alt="" />
                {getWorkplaceAddress(jobOpportunity)}
              </div>
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={15}
                options={{
                  fullscreenControl: false,
                  mapTypeControl: false,
                  streetViewControl: false
                }}
              >
                <Marker position={center} />
              </GoogleMap>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

JobDetail.propTypes = {
  jobOpportunity: PropTypes.object.isRequired
};

JobDetail.defaultProps = {};

export default JobDetail;
