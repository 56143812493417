import { track } from '@amplitude/analytics-browser';

export const trackResetPassword = (eventName, queryParams, additionalData) => {
  const utm_source = queryParams.get('utm_source');
  const utm_campaign = queryParams.get('utm_campaign');
  const emailParam = queryParams.get('e');
  const smsParam = queryParams.get('s');
  const isEmailSource = emailParam !== null && emailParam !== undefined;
  const isSmsSource = smsParam !== null && smsParam !== undefined;
  const utm_medium = isEmailSource ? 'email' : 'sms';

  track(eventName, {
    utm_source,
    utm_medium: isEmailSource || isSmsSource ? utm_medium : null,
    utm_campaign,
    password_set_numer: isEmailSource ? emailParam : smsParam,
    ...additionalData
  });
};

export default trackResetPassword;
