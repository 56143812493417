import React from 'react';
import PropTypes from 'prop-types';
import Slate from 'CommonComponents/slate/slate.component';
import { Button } from '@jobandtalent/design-system';
import I18n from 'lib/i18n';
import { track } from '@amplitude/analytics-browser';
import { useStore } from 'Jobfeed/store/hook';
import noresultSlateSvg from 'Images/img_blank_state_search.svg';

// Stylesheets
import './no-results.css.less';

const NoResults = ({ title, info }) => {
  const store = useStore();
  const countryCode = store.getCountryCode();

  const resetFilters = () => {
    track('filters_applied', {
      action: 'apply',
      number_categories: 0,
      number_regions: 0,
      filter_location_country_values: countryCode,
      filter_location_region_values: [],
      filter_category_values: []
    });

    store.resetFilters();
  };

  return (
    <Slate svg={noresultSlateSvg} title={title} subtitle={info} className="Slate--NoResults">
      <div className="Slate__content">
        <Button onSelect={resetFilters} primary>
          {I18n.t('jobfeed.filters.reset_filters')}
        </Button>
      </div>
    </Slate>
  );
};

NoResults.propTypes = {
  title: PropTypes.string,
  info: PropTypes.string
};

NoResults.defaultProps = {
  title: '',
  info: ''
};

export default NoResults;
