import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

// Stylesheets
import './sticky-bar.component.css.less';
import { FiltersContext } from 'Jobfeed/context/FiltersContext';
import { isMobile } from 'Jobfeed/services/tracking.service';

const StickyBar = ({ children, enabled }) => {
  const filtersContext = React.useContext(FiltersContext);
  const onBackdropClick = () => {
    filtersContext.closeFilter();
  };

  return (
    <>
      <div
        className={classnames('StickyBar', {
          'StickyBar--open': filtersContext?.isFilterOpened,
          'StickyBar--enabled': enabled
        })}
      >
        {children}
        {filtersContext?.isFilterOpened && isMobile() ? (
          <div
            className="StickyBar__mobile-backdrop"
            onClick={onBackdropClick}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                onBackdropClick();
              }
            }}
            tabIndex={0}
            role="button"
          />
        ) : null}
      </div>
      {filtersContext?.shouldDisplayBackdrop ? (
        <div
          className="StickyBar__backdrop"
          onClick={onBackdropClick}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              onBackdropClick();
            }
          }}
          tabIndex={0}
          role="button"
        />
      ) : null}
    </>
  );
};

StickyBar.propTypes = {
  children: PropTypes.node,
  enabled: PropTypes.bool.isRequired
};

StickyBar.defaultProps = {
  children: null
};

export default StickyBar;
