import React, { useState } from 'react';
import PropTypes from 'prop-types';

import I18n from 'lib/i18n';

// Components
import Modal from 'CommonComponents/modal/modal.component';
import ResetPasswordForm from 'Jobfeed/components/reset-password/reset-password-form.component';
import ResetPasswordSuccess from 'Jobfeed/components/reset-password/reset-password-success.component';
import failureDocumentsSvg from 'Images/failure_documents.svg';

const ResetPassword = ({ token, email, source, closeResetPassword }) => {
  const [stepToShow, setStepToShow] = useState('FORM');

  return (
    <>
      <Modal
        onHide={closeResetPassword}
        className="reset-password-modal-overlay reset-password-modal"
      >
        <div className="reset-password-content-wrapper">
          {stepToShow === 'LINK_EXPIRED' && (
            <>
              <img className="reset-password-link-expired-image" src={failureDocumentsSvg} alt="" />
              <h1 className="reset-password-header">
                {I18n.t('jobfeed.password_reset.password.link_expired.title')}
              </h1>
              <p className="reset-password-instructions">
                {I18n.t('jobfeed.password_reset.password.link_expired.text')}
              </p>
            </>
          )}
          {stepToShow === 'SUCCESS' && <ResetPasswordSuccess email={email} />}
          {stepToShow === 'FORM' && (
            <>
              <h1 className="reset-password-header">
                {I18n.t('jobfeed.password_reset.password.title')}
              </h1>
              <p className="reset-password-instructions">
                {I18n.t('jobfeed.signup.errors.invalid')}
              </p>
              <ResetPasswordForm
                token={token}
                email={email}
                source={source}
                showSuccess={() => setStepToShow('SUCCESS')}
                showError={() => setStepToShow('LINK_EXPIRED')}
              />
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

ResetPassword.propTypes = {
  token: PropTypes.string.isRequired,
  email: PropTypes.string,
  source: PropTypes.string,
  closeResetPassword: PropTypes.func.isRequired
};

ResetPassword.defaultProps = {
  email: null,
  source: null
};

export default ResetPassword;
