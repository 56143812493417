import React from 'react';
import classnames from 'classnames';
import { Button } from '@jobandtalent/design-system';
import I18n from 'lib/i18n';
import PropTypes from 'prop-types';

// Stylesheets
import './job-detail-sidebar.component.css.less';

const Sidebar = ({ apply, applyMobile, jobOpportunity }) => {
  return (
    <div className="JobDetail__sidebar">
      <div className="JobDetail__sidebar-wrapper">
        <div className="JobDetail__get-job">
          <div className="JobDetail__get-job-vacancies">
            <div
              className={classnames('JobDetail__get-job-vacancies-left', {
                'JobDetail__get-job-vacancies-left--highlighted': jobOpportunity.vacanciesLeft <= 5
              })}
            >
              {I18n.t('jobfeed.job_detail.vacancies_left', {
                count: jobOpportunity.vacanciesLeft
              })}
            </div>
            <div className="JobDetail__get-job-vacancies-total">
              {I18n.t('jobfeed.job_detail.vacancies_total', {
                count: jobOpportunity.vacanciesTotal
              })}
            </div>
          </div>
          <Button
            className="JobDetail__sidebar__action-button ds-atom-btn-primary-normal"
            onSelect={apply}
          >
            {I18n.t('jobfeed.apply')}
          </Button>
          <button
            type="button"
            onClick={applyMobile}
            className="JobDetail__sidebar__action-link ds-atom-btn-primary-normal"
          >
            {I18n.t('jobfeed.apply')}
          </button>
        </div>
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  jobOpportunity: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    vacanciesLeft: PropTypes.number,
    vacanciesTotal: PropTypes.number,
    hideCompanyInfo: PropTypes.bool
  }).isRequired,
  apply: PropTypes.func,
  applyMobile: PropTypes.func
};

Sidebar.defaultProps = {
  apply: () => {},
  applyMobile: () => {}
};

export default Sidebar;
