import * as jobsActions from 'Jobfeed/store/actions_jobs';
import * as filterActions from 'Jobfeed/store/actions_filters';
import * as geolocationActions from 'Jobfeed/store/actions_geolocation';
import * as behaviourActions from 'Jobfeed/store/actions_behaviour';

export default {
  ...jobsActions,
  ...filterActions,
  ...geolocationActions,
  ...behaviourActions
};
