class ApiClient {
  constructor({ defaultHeaders } = {}) {
    this.defaultHeaders = defaultHeaders;
  }

  get(url) {
    // Request with GET/HEAD method cannot have body
    return this.handleRequest(url, 'GET');
  }

  post(url, params, token) {
    return this.handleRequest(url, 'POST', params, {
      'X-CSRF-Token': token
    });
  }

  postFormData(url, data, csrfToken) {
    return fetch(url, {
      method: 'POST',
      body: this.buildFormData(data),
      headers: {
        'X-CSRF-Token': csrfToken
      }
    });
  }

  putFormData(url, data, token) {
    return fetch(url, {
      method: 'PUT',
      body: this.buildFormData(data),
      headers: {
        'X-CSRF-Token': token
      }
    });
  }

  patch(url, params) {
    return this.handleRequest(url, 'PATCH', params);
  }

  delete(url, csrfToken) {
    return fetch(url, {
      method: 'DELETE',
      headers: {
        'X-CSRF-Token': csrfToken
      }
    });
  }

  handleResponse(response) {
    if (response.ok) {
      // Note that the promise won't be rejected in case of HTTP 4xx or 5xx server
      // responses. The promise will be resolved just as it would be for HTTP 2xx.
      // Inspect the response.status number within the resolved callback to add
      // conditional handling of server errors to your code.
      //
      // Reference: https://github.github.io/fetch/#error
      //
      return response;
    }

    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  }

  processResponse(response) {
    return response.json();
  }

  buildHeaders() {
    const baseHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    };

    return { ...baseHeaders, ...this.defaultHeaders };
  }

  buildBody(params) {
    return JSON.stringify(params);
  }

  buildFormData(data) {
    const formData = new FormData();
    // eslint-disable-next-line no-restricted-syntax
    for (const key of Object.keys(data)) {
      const value = data[key];
      if (value !== null && value !== undefined) {
        if (value instanceof Object) {
          Object.keys(value).forEach(itemKey => {
            const itemValue = value[itemKey];
            formData.append(`${key}[${itemKey}]`, itemValue);
          });
        } else {
          formData.append(key, value);
        }
      }
    }
    return formData;
  }

  handleRequest(url, method, params, extraHeaders = {}) {
    return fetch(url, {
      headers: {
        ...this.buildHeaders(),
        ...extraHeaders
      },
      method,
      body: this.buildBody(params)
    })
      .then(this.handleResponse)
      .then(this.processResponse);
  }
}

export default ApiClient;
