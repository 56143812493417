import {
  FETCH_GEOLOCATION_MAP_URL_SIGNATURE_SUCCESS,
  FETCH_GEOLOCATION_MAP_URL_SIGNATURE_FAILURE
} from 'Jobfeed/store/actions_names';
import GeolocationService from '../services/geolocation.service';

export const fetchGeolocationMapUrlSignatureSuccess = ({ url, signature }) => ({
  type: FETCH_GEOLOCATION_MAP_URL_SIGNATURE_SUCCESS,
  payload: { url, signature }
});

export const fetchGeolocationMapUrlSignatureFailure = () => ({
  type: FETCH_GEOLOCATION_MAP_URL_SIGNATURE_FAILURE
});

export const fetchGeolocationMapUrlSignature = url => async (dispatch, getState) => {
  const state = getState();
  let signature = state.jobfeed.mapUrlSignatureCache[url];
  if (signature == null) {
    try {
      signature = await GeolocationService.createMapUrlSignature(url, state.csrfToken);
      dispatch(fetchGeolocationMapUrlSignatureSuccess({ url, signature }));
    } catch (error) {
      dispatch(fetchGeolocationMapUrlSignatureFailure());
    }
  }
};
