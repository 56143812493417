export default class JobOpportunity {
  constructor(json) {
    this.id = json.id;
    this.slug = json.slug;
    this.title = json.title;
    this.companyName = json.companyName || json.company_name;
    this.geodatum = json.geodatum;
    this.formattedLocation = json.formattedLocation || json.formatted_location;
    this.startDate = json.startDate || json.start_date;
    this.createdAt = json.createdAt || json.created_at;
    this.salary = json.salary;
    this.description = json.description;
    this.responsibilities = json.responsibilities;
    this.payRate = json.payRate || json.pay_rate;
    this.vacanciesLeft = json.vacanciesLeft || json.vacancies_left;
    this.vacanciesTotal = json.vacanciesTotal || json.vacancies_total;
    this.hideCompanyInfo = json.hideCompanyInfo || json.hide_company_info;
    this.slug = json.slug;
    this.validThrough = json.valid_through;
    this.jobFunctionSlug = json.jobFunctionSlug || json.job_function_slug;

    if (this?.geodatum?.country) {
      this.geodatum.country.countryCode =
        json.geodatum.country.countryCode || json.geodatum.country.country_code;
    }

    if (this?.geodatum) {
      this.geodatum.formattedHiddenLocation =
        json.geodatum.formattedHiddenLocation || json.geodatum.formatted_hidden_location;
    }

    if (this?.geodatum) {
      this.geodatum.streetNumber = json.geodatum.streetNumber || json.geodatum.street_number;
      this.geodatum.postalCode = json.geodatum.postalCode || json.geodatum.postal_code;
    }

    if (this?.geodatum?.subdivision) {
      this.geodatum.subdivision.verboseName =
        json.geodatum.subdivision.verboseName || json.geodatum.subdivision.verbose_name;
    }
  }
}
