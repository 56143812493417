import 'Common/polyfill';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ConnectedRouter, connectRouter, routerMiddleware } from 'connected-react-router';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { ActionCableProvider } from 'CommonComponents/action-cable';

// Stylesheets
import 'Jobfeed/application.component.css.less';

// Libs
import I18n from 'lib/i18n';

// Reducers
import jobfeed from 'Jobfeed/store';

// Components
import JobfeedRouter from 'CommonComponents/router/jobfeed-router.component';
import Navbar from 'CommonComponents/navbar/navbar.component';
import CookieConsent from 'Jobfeed/components/cookie-consent/cookie-consent.component';
import SessionOptions from 'Common/containers/navbar/session_options.container';

// Paths
import usePaths from 'CommonComponents/router/jobfeed/paths';
import { FiltersContextProvider } from 'Jobfeed/context/FiltersContext';
import { GlobalContextProvider } from 'Jobfeed/context/GlobalContext';
import FiltersBar from 'Common/components/filters-bar/filters-bar.component';
import StickyBar from 'Common/components/sticky-bar/sticky-bar.component';
import CategoryFilter from 'Common/components/category-filter/category-filter.component';
import CountryFilter from 'Common/components/country-filter/country-filter.component';
import RegionFilter from 'Common/components/region-filter/region-filter.component';

import {
  getInitialJobOpportunities,
  getInitialJobOpportunity
} from 'Jobfeed/helpers/job_opening.helpers';

I18n.addTranslation('jobfeed');

const paths = usePaths();

const Application = ({ defaultState, csrfToken }) => {
  const [i18nReady, setI18nReady] = useState(false);

  I18n.setLocale(defaultState.locale);
  I18n.ready.then(() => {
    setI18nReady(true);
  });

  const App = () => {
    const history = createBrowserHistory({
      basename: paths.basename()
    });

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const createRootReducer = () =>
      combineReducers({
        jobfeed,
        router: connectRouter(history),
        csrfToken: () => csrfToken
      });

    const jobOpportunities = getInitialJobOpportunities(
      defaultState.jobOpportunities,
      defaultState.jobOpportunity
    );
    const jobOpportunity = getInitialJobOpportunity(jobOpportunities, defaultState.jobOpportunity);

    const store = createStore(
      createRootReducer(),
      {
        jobfeed: {
          ...defaultState,
          jobOpportunity,
          jobOpportunities
        }
      },
      composeEnhancers(applyMiddleware(routerMiddleware(history), thunk))
    );

    if (defaultState.focusOn) {
      document.body.classList.add('FocusOn');
    }

    return (
      <Provider store={store}>
        <GlobalContextProvider defaultState={defaultState}>
          <ActionCableProvider>
            <FiltersContextProvider>
              <ConnectedRouter history={history}>
                <div className="MainApplication notranslate">
                  <header className="MainApplication__header">
                    <Navbar>
                      <SessionOptions
                        openPasswordResetOnLoad={defaultState.resetPassword === true}
                        resetPasswordToken={defaultState.resetPasswordToken}
                        email={defaultState.email}
                        source={defaultState.source}
                      />
                    </Navbar>
                  </header>
                  {!defaultState.resetPassword && (
                    <>
                      <StickyBar enabled>
                        <FiltersBar>
                          <CountryFilter />
                          <RegionFilter />
                          <CategoryFilter />
                        </FiltersBar>
                      </StickyBar>
                      <section className="MainApplication__content">
                        <JobfeedRouter basename={paths.basename()} history={history} />
                      </section>
                    </>
                  )}
                </div>
                <CookieConsent cookieName="jt_cookie_consent" />
              </ConnectedRouter>
            </FiltersContextProvider>
          </ActionCableProvider>
        </GlobalContextProvider>
      </Provider>
    );
  };
  return !i18nReady ? <p>Loading</p> : <App />;
};

Application.propTypes = {
  defaultState: PropTypes.object.isRequired,
  csrfToken: PropTypes.string.isRequired
};

export default Application;
