import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import I18n from 'lib/i18n';
import { Button, InputText } from '@jobandtalent/design-system';
import PasswordInput from 'Jobfeed/components/password-input/password-input.component';
import { useFetch } from 'Common/hooks/use-fetch.hook';
import SessionsService from 'Jobfeed/services/sessions.service';
import './reset-password-form.component.css.less';
import { useStore } from 'Jobfeed/store/hook';
import { flush } from '@amplitude/analytics-browser';
import { trackResetPassword } from './track';

const ResetPasswordForm = ({ token, email, source, showSuccess, showError }) => {
  const [password, setPassword] = useState('');
  const [working, setWorking] = useState(false);
  const [repeatPassword, setRepeatPassword] = useState('');
  const store = useStore();
  const countryCode = store.getCountryCode();
  const queryParams = store.getQueryParams();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const csrfToken = useSelector(state => state.csrfToken);

  const [resetPasswordState, resetPasswordFetch] = useFetch(params =>
    SessionsService.resetPassword(params, csrfToken)
  );

  useEffect(() => {
    const extraParams = {
      country_code: countryCode?.toLowerCase()
    };

    if (queryParams?.get('hash')) {
      extraParams.token = queryParams?.get('hash');
    }

    trackResetPassword('set_password_website_visited', queryParams, extraParams);

    flush();

    return () => {};
  }, []);

  useEffect(() => {
    if (working) {
      setWorking(false);
    }

    if (resetPasswordState.status === 'errored') {
      showError();
    }

    if (resetPasswordState.status === 'fetched') {
      const { ok } = resetPasswordState?.data;

      if (ok) {
        showSuccess();
      }
    }

    if (resetPasswordState.status === 'fetching') {
      setWorking(true);
    }
  }, [resetPasswordState]);

  const passwordChange = e => {
    setPassword(e.target.value);
  };

  const repeatPasswordChange = e => {
    setRepeatPassword(e.target.value);
  };

  const handleSubmit = () => {
    resetPasswordFetch({ password, repeatPassword, token, email, source });
  };

  const canSubmit = useMemo(
    () =>
      password.length &&
      repeatPassword.length &&
      password === repeatPassword &&
      password.match(`^(?!\\s)(?![\\s\\S]*\\s$)(?=.*[a-z])(?=.*[A-Z]).{12,128}$`),
    [password, repeatPassword]
  );

  const onSubmit = async e => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (canSubmit && !isSubmitting) {
      setIsSubmitting(true);

      try {
        await handleSubmit();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        showError();
      }
    }
  };

  const isPasswordConfirmInvalid = () => {
    if (repeatPassword && password !== repeatPassword) {
      return true;
    }

    return false;
  };

  return (
    <form onSubmit={onSubmit} data-testid="ResetPasswordForm-form">
      <div className="ResetPassword__fieldset">
        <div className="ResetPassword__field">
          <div className="ResetPassword__field-labelGroup">
            <label htmlFor="email" className="ResetPassword__field-label">
              {I18n.t('jobfeed.signin.email_label')}
            </label>
          </div>
          <div className="GenericPassword">
            <InputText
              type="text"
              className="GenericPassword__input ResetPassword__field-input ResetPassword__field-input-email"
              id="email"
              readOnly
              value={email || ''}
            />
          </div>
        </div>
        <div className="ResetPassword__field">
          <div className="ResetPassword__field-labelGroup">
            <label htmlFor="password" className="ResetPassword__field-label">
              {I18n.t('jobfeed.password_reset.password.input_1')}
            </label>
            <label
              htmlFor="password"
              className="ResetPassword__field-label ResetPassword__field-label-pwd"
            >
              {I18n.t('jobfeed.signin.password_num_chars')}
            </label>
          </div>
          <PasswordInput
            handleOnChange={passwordChange}
            validation={{
              required: {
                value: true,
                errorMessage: I18n.t('jobfeed.signup.errors.empty_password')
              },
              pattern: {
                // It will match only if password has at least one uppercase, lowercase and minimum length of 12.
                value: `^(?!\\s)(?![\\s\\S]*\\s$)(?=.*[a-z])(?=.*[A-Z]).{12,128}$`,
                errorMessage: I18n.t('jobfeed.signup.errors.invalid')
              }
            }}
            className="ResetPassword__field-input"
            inputProps={{
              id: 'password',
              'data-testid': 'password',
              autoComplete: 'new-password',
              placeholder: I18n.t('jobfeed.password_reset.password.password_placeholder')
            }}
          />
        </div>
        <div className="ResetPassword__field">
          <div className="ResetPassword__field-labelGroup">
            <label htmlFor="password_repeat" className="ResetPassword__field-label">
              {I18n.t('jobfeed.password_reset.password.input_2')}
            </label>
            <label
              htmlFor="password_repeat"
              className="ResetPassword__field-label ResetPassword__field-label-pwd"
            >
              {I18n.t('jobfeed.signin.password_num_chars')}
            </label>
          </div>

          <PasswordInput
            handleOnChange={repeatPasswordChange}
            makeInvalid={isPasswordConfirmInvalid()}
            validationErrorMessage={I18n.t('jobfeed.password_reset.password.error')}
            validation={{
              required: {
                value: true,
                errorMessage: I18n.t('jobfeed.signup.errors.empty_password')
              }
            }}
            className="ResetPassword__field-input"
            inputProps={{
              id: 'password_repeat',
              'data-testid': 'password-repeat',
              autoComplete: 'new-password_repeat',
              placeholder: I18n.t('jobfeed.password_reset.password.password_placeholder')
            }}
          />
        </div>
      </div>
      <Button
        className="ResetPassword__submit-button ds-atom-btn-primary-normal"
        type="submit"
        disabled={!canSubmit || isSubmitting}
        working={working}
        onSelect={onSubmit}
      >
        {I18n.t('jobfeed.password_reset.password.action')}
      </Button>
    </form>
  );
};

ResetPasswordForm.propTypes = {
  token: PropTypes.string.isRequired,
  email: PropTypes.string,
  source: PropTypes.string,
  showSuccess: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired
};

ResetPasswordForm.defaultProps = {
  email: null,
  source: null
};

export default ResetPasswordForm;
