import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'Jobfeed/services/tracking.service';

const initialState = {
  visible: false,
  selectedRegions: [],
  selectedJobFunctions: [],
  isFilterOpened: false,
  openedFilterId: null
};

const actions = {
  SHOW_FILTERS: 'SHOW_FILTERS',
  HIDE_FILTERS: 'HIDE_FILTERS',
  OPEN_FILTER: 'OPEN_FILTER',
  CLOSE_FILTER: 'CLOSE_FILTER'
};

const filtersWithoutBackdrop = ['sort'];

const reducer = (state, action) => {
  switch (action.type) {
    case actions.SHOW_FILTERS:
      return {
        savedCountry: action.payload.selectedCountry,
        selectedRegions: action.payload.selectedRegions,
        selectedJobFunctions: action.payload.selectedJobFunctions,
        visible: true
      };
    case actions.OPEN_FILTER: {
      return {
        ...state,
        openedFilterId: action.payload.filterId,
        isFilterOpened: true
      };
    }
    case actions.CLOSE_FILTER: {
      return {
        ...state,
        openedFilterId: null,
        isFilterOpened: false
      };
    }
    case actions.HIDE_FILTERS: {
      return { visible: false };
    }
    default:
      return state;
  }
};

export const FiltersContext = React.createContext();

export const FiltersContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const value = {
    visible: state.visible,
    isFilterOpened: state.isFilterOpened,
    openedFilterId: state.openedFilterId,
    shouldDisplayBackdrop: isMobile()
      ? state.isFilterOpened
      : filtersWithoutBackdrop.indexOf(state.openedFilterId) === -1 && state.isFilterOpened,
    showFilters: (selectedCountry, selectedRegions, selectedJobFunctions) => {
      dispatch({
        type: actions.SHOW_FILTERS,
        payload: {
          selectedCountry,
          selectedRegions,
          selectedJobFunctions
        }
      });
    },
    openFilter: filterId => {
      dispatch({
        type: actions.OPEN_FILTER,
        payload: {
          filterId
        }
      });
    },
    closeFilter: filterId => {
      dispatch({
        type: actions.CLOSE_FILTER,
        payload: {
          filterId
        }
      });
    },
    toggleFilter: () => {
      if (state.isFilterOpened) {
        dispatch({
          type: actions.CLOSE_FILTER
        });
      } else {
        dispatch({
          type: actions.OPEN_FILTER
        });
      }
    },
    hideFilters: callback => {
      dispatch({ type: actions.HIDE_FILTERS });

      if (typeof callback === 'function') {
        callback(state);
      }
    }
  };

  return <FiltersContext.Provider value={value}>{children}</FiltersContext.Provider>;
};

FiltersContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};
