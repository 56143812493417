import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { track } from '@amplitude/analytics-browser';
import { useHistory } from 'react-router-dom';

import Sidebar from 'Jobfeed/components/job-detail-sidebar/job-detail-sidebar.component';
import DetailView from 'Jobfeed/containers/job-detail/views/JobDetail';
import { isMobile, getUTMData } from 'Jobfeed/services/tracking.service';

import { useStore } from 'Jobfeed/store/hook';

import './job-detail.component.css.less';
import { JobOpportunityContext } from 'Jobfeed/context/JobOpeningContext';
import JobApplyNow from 'Jobfeed/components/job-apply-now/job-apply-now.component';
import JobDownloadOnMobile from 'Jobfeed/components/job-download-on-mobile/job-download-on-mobile.component';
import JobOpportunitiesService from 'Jobfeed/services/job-opening.service';
import backIcon from 'Common/images/back.svg';
import { GlobalContext } from 'Jobfeed/context/GlobalContext';

const JobDetail = ({ jobOpportunity }) => {
  const history = useHistory();
  const jobOpportunityContext = React.useContext(JobOpportunityContext);
  const globalContext = React.useContext(GlobalContext);
  const store = useStore();

  const csrfToken = store.getCsrfToken();

  const applyJob = () => {
    track('get_job_button_clicked', {
      job_opportunity_id: jobOpportunity.id,
      registered: false,
      anonymized: jobOpportunity.hideCompanyInfo,
      ...getUTMData()
    });

    jobOpportunityContext.showApplyNow();
  };

  const applyMobile = () => {
    track('get_job_button_clicked', {
      job_opportunity_id: jobOpportunity.id,
      registered: false,
      anonymized: jobOpportunity.hideCompanyInfo,
      ...getUTMData()
    });

    jobOpportunityContext.showDownloadOnMobile();
  };

  useEffect(() => {
    const fetchDeepLink = async () => {
      if (!globalContext.amplitudeDeviceId) return null;

      const response = await JobOpportunitiesService.getDeepLink(jobOpportunity.id, csrfToken, {
        web_device_id: globalContext.amplitudeDeviceId,
        referrer: document.referrer,
        referring_domain: new URL(window.location.origin).hostname,
        landing_page: window.location.origin + window.location.pathname
      });

      if (response?.data?.job_opportunity?.deeplink) {
        jobOpportunityContext.setDeeplink(response?.data?.job_opportunity?.deeplink);
      }

      return response;
    };

    fetchDeepLink();
  }, [jobOpportunity.id, globalContext.amplitudeDeviceId]);

  useEffect(() => {
    if (isMobile()) {
      try {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } catch {
        window.scrollTo({ top: 0 });
      }
    }
  }, [jobOpportunity?.id]);

  return (
    <div className="JobDetail">
      <button
        className="JobDetail__back"
        type="button"
        onClick={() => {
          if (history && history.length > 2) {
            history.goBack();
          } else {
            history.push('/');
          }
        }}
      >
        <img className="JobDetail__back-icon" src={backIcon} alt="" />
      </button>
      {jobOpportunityContext?.isQRVisible && <JobApplyNow />}
      {jobOpportunityContext?.isDownloadOnMobileVisible && <JobDownloadOnMobile />}
      <DetailView jobOpportunity={jobOpportunity} applyJob={applyJob} />
      <Sidebar jobOpportunity={jobOpportunity} apply={applyJob} applyMobile={applyMobile} />
    </div>
  );
};

JobDetail.propTypes = {
  jobOpportunity: PropTypes.object
};
JobDetail.defaultProps = {
  jobOpportunity: {}
};

export default JobDetail;
