import 'Common/polyfill';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';

// Stylesheets
import 'Jobfeed/application.component.css.less';

// Libs
import I18n from 'lib/i18n';

// Reducers
import jobfeed from 'Jobfeed/store';

// Components
import { Button } from '@jobandtalent/design-system';
import Navbar from 'CommonComponents/navbar/navbar.component';
import Footer from 'CommonComponents/footer/footer.component';
import Wrap from 'CommonComponents/wrap/wrap.component';
import Slate from 'CommonComponents/slate/slate.component';

// Images
import noresultSlateSvg from 'Images/noresult-slate-image.svg';

I18n.addTranslation('jobfeed');

if (document) {
  I18n.setLocale(document.documentElement.lang);
} else {
  I18n.setLocale('en-GB');
}

const Application = ({ defaultState, csrfToken }) => {
  const [i18nReady, setI18nReady] = useState(false);

  I18n.ready.then(() => {
    setI18nReady(true);
  });

  const App = () => {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const createRootReducer = () =>
      combineReducers({
        jobfeed,
        csrfToken: () => csrfToken
      });

    const store = createStore(
      createRootReducer(),
      { jobfeed: defaultState },
      composeEnhancers(applyMiddleware(thunk))
    );

    const redirect = () => {
      if (typeof window !== 'undefined') {
        const url = `/${defaultState.countryCode.toLowerCase()}`;
        window.location.replace(url);
      }
    };

    return (
      <Provider store={store}>
        <div className="MainApplication">
          <header className="MainApplication__header">
            <Navbar />
          </header>
          <section className="MainApplication__content">
            <Wrap className="Wrap--center">
              <Slate
                svg={noresultSlateSvg}
                title={
                  <>
                    {I18n.t('jobfeed.we.404.title.one')}
                    <br />
                    {I18n.t('jobfeed.we.404.title.two')}
                  </>
                }
                subtitle={I18n.t('jobfeed.404.desc')}
              >
                <div className="Slate__content">
                  <Button onSelect={redirect} primary>
                    {I18n.t('jobfeed.404.button')}
                  </Button>
                </div>
              </Slate>
            </Wrap>
          </section>
          <footer className="MainApplication__footer">
            <Footer />
          </footer>
        </div>
      </Provider>
    );
  };
  return !i18nReady ? <p>Loading</p> : <App />;
};

Application.propTypes = {
  defaultState: PropTypes.object.isRequired,
  csrfToken: PropTypes.string.isRequired
};

export default Application;
