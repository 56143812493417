// Jobfeed tool paths

const basename = '';

export const JobfeedPaths = () => {
  return {
    basename: () => basename,
    jobs: () => '/:country/:main_filter?',
    defaultJobs: country => `/${country}/`,
    job: () => '/:country/:jobFunctionSlug/:region/:jobSlug'
  };
};

export default JobfeedPaths;
