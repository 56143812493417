import JobOpportunity from 'Jobfeed/models/job-opening.model';

export const jobParamsFromJobOpportunity = (params = {}) => {
  const { id } = params;

  return {
    country: 'country',
    jobFunctionSlug: 'jobFunctionSlug',
    jobSlug: 'jobSlug',
    job_opening_id: id
  };
};

export const getInitialJobOpportunities = (jobOpportunities = [], jobOpportunity) => {
  const initialJobOpportunities = jobOpportunities.map(jo => {
    return new JobOpportunity(jo);
  });

  if (jobOpportunity?.id && jobOpportunities.some(jo => jo.id === jobOpportunity.id) === false) {
    initialJobOpportunities.unshift(jobOpportunity);
  }

  if (jobOpportunity?.id && jobOpportunities.some(jo => jo.id === jobOpportunity.id) === true) {
    initialJobOpportunities.unshift(
      initialJobOpportunities.splice(
        initialJobOpportunities.findIndex(jo => jo.id === jobOpportunity.id),
        1
      )[0]
    );
  }

  return initialJobOpportunities;
};

export const getInitialJobOpportunity = (jobOpportunities = [], jobOpportunity = {}) => {
  let initialJobOpportunity;

  if (jobOpportunity?.id) {
    initialJobOpportunity = new JobOpportunity(jobOpportunity);
  } else if (jobOpportunities?.length) {
    [initialJobOpportunity] = jobOpportunities;
  }

  return initialJobOpportunity;
};
