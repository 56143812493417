import React from 'react';
import PropTypes from 'prop-types';
import * as amplitude from '@amplitude/analytics-browser';
import { useJsApiLoader } from '@react-google-maps/api';

export const GlobalContext = React.createContext();

const googleLibraries = ['places'];

export const GlobalContextProvider = ({ defaultState, children }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    libraries: googleLibraries,
    googleMapsApiKey: defaultState.googleMapsApiKeyPublic
  });
  const [amplitudeReady, setAmplitudeReady] = React.useState(false);
  const [amplitudeDeviceId, setAmplitudeDeviceId] = React.useState(null);
  const [state, setState] = React.useState({
    hasJOSelected: !!defaultState.jobOpportunity?.id
  });

  amplitude.init(defaultState.amplitudeApiKey).promise.then(() => {
    setAmplitudeReady(true);
  });

  const value = {
    googleMapsApiKeyPublic: defaultState.googleMapsApiKeyPublic,
    googleMapsLoaded: isLoaded,
    amplitudeReady,
    amplitudeDeviceId,
    setAmplitudeDeviceId,
    firebaseApiKeyPublic: defaultState.firebaseApiKeyPublic,
    firebaseDynamicLinkDomain: defaultState.firebaseDynamicLinkDomain,
    firebaseMainDomain: defaultState.firebaseMainDomain,
    firebaseApn: defaultState.firebaseApn,
    firebaseIbi: defaultState.firebaseIbi,
    firebaseIsi: defaultState.firebaseIsi,
    ...state,
    setHasJOSelected: hasJOSelected => {
      setState({ ...state, hasJOSelected });
    }
  };

  return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>;
};

GlobalContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  defaultState: PropTypes.shape({
    googleMapsApiKeyPublic: PropTypes.string.isRequired,
    jobOpportunity: PropTypes.object,
    firebaseApiKeyPublic: PropTypes.string.isRequired,
    firebaseDynamicLinkDomain: PropTypes.string.isRequired,
    firebaseMainDomain: PropTypes.string.isRequired,
    firebaseApn: PropTypes.string.isRequired,
    firebaseIbi: PropTypes.string.isRequired,
    firebaseIsi: PropTypes.string.isRequired,
    amplitudeApiKey: PropTypes.string.isRequired
  }).isRequired
};
