import React from 'react';
import PropTypes from 'prop-types';

import JobDetailDescription from 'Jobfeed/components/job-detail/job-detail.component';
import { useStore } from 'Jobfeed/store/hook';

const JobDetail = ({ jobOpportunity, applyJob }) => {
  const store = useStore();
  const jobFunctions = store.getJobFunctions();

  return (
    <JobDetailDescription
      jobOpportunity={jobOpportunity}
      jobFunctions={jobFunctions}
      apply={applyJob}
    />
  );
};

JobDetail.propTypes = {
  jobOpportunity: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  applyJob: PropTypes.func
};

JobDetail.defaultProps = {
  jobOpportunity: {
    id: null
  },
  applyJob: () => {}
};

export default JobDetail;
