import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import I18n from 'lib/i18n';
import { track, flush } from '@amplitude/analytics-browser';
import classnames from 'classnames';
import InfiniteScroll from 'react-infinite-scroll-component';

// Store
import { useStore } from 'Jobfeed/store/hook';

// Services
import CountriesService from 'Common/services/countries.service';

// Components
import JobItem from 'Jobfeed/components/job-item/job-item.component';
import JobListHeader from 'Jobfeed/components/job-list-header/job-list-header.component';
import CountryNotSupported from 'Jobfeed/components/country-not-supported/country-not-supported';
import NoResults from 'Jobfeed/components/no-results/no-results';
import Footer from 'Common/components/footer/footer.component';

// Stylesheets
import './job-list.component.css.less';

const JobList = ({ jobOpportunity }) => {
  const store = useStore();
  const landingsUrl = store.getLandingsUrl();
  const perPage = store.getPerPage();
  const jobOpportunities = store.getJobOpportunities();
  const totalNumber = store.getTotalNumber();
  const sortBy = store.getSortBy();
  const sortDir = store.getSortDir();
  const countryCode = store.getCountryCode();
  const selectedRegions = store.getSelectedRegions();
  const selectedJobFunctions = store.getSelectedJobFunctions();
  const fetchingSuccessful = store.isJobListFetchingSuccessful();
  const loading = store.isJobListLoading();
  const countriesService = new CountriesService();
  const notSupportedCountries = countriesService.notSupportedCountries();
  const [countrySupported, setCountrySupported] = useState(
    !notSupportedCountries.some(country => country.code === countryCode)
  );
  const [page, setPage] = useState(1);

  useEffect(() => {
    const newCountrySupported = !notSupportedCountries.some(
      country => country.code === countryCode
    );
    setCountrySupported(newCountrySupported);
  }, [countryCode]);

  const createJobItemList = () => {
    const classes = loading ? 'JobItem--loading' : '';

    const jobs = jobOpportunities.map(item => {
      const jobCountryCode = item.geodatum?.country?.countryCode?.toLowerCase() || 'es';
      const regionSlug = item.geodatum.subdivision.slug;
      const jobRoute = `/${jobCountryCode}/${item.jobFunctionSlug}/${regionSlug}/${item.slug}?locale=${jobCountryCode}`;

      return (
        <JobItem
          className={classnames(classes, {
            'JobItem--selected': jobOpportunity && jobOpportunity.id === item.id
          })}
          key={item.id}
          jobOpportunity={item}
          buttonLabel={I18n.t('jobfeed.view_job')}
          href={jobRoute}
          onAction={e => {
            e.preventDefault();

            track('job_card_clicked', {
              job_opportunity_id: item.id,
              distance_show: null,
              distance_unit: 'km',
              vacancies_left: item.vacanciesLeft <= 5,
              vacancies_left_number: item.vacanciesLeft,
              anonymized: item.hideCompanyInfo
            });

            flush();

            store.goToJobDetail(item, window?.location?.search);
          }}
        />
      );
    });

    return jobs;
  };

  const jobItemList = createJobItemList();

  return (
    <div className="JobList">
      {(!fetchingSuccessful || !jobOpportunities.length) && countrySupported ? (
        <>
          <NoResults
            title={I18n.t('jobfeed.no_results.first_line')}
            info={I18n.t('jobfeed.no_results.second_line')}
          />
          <footer>
            <Footer landingsUrl={landingsUrl} />
          </footer>
        </>
      ) : null}
      {countrySupported === false ? (
        <CountryNotSupported
          url={notSupportedCountries.find(country => country.code === countryCode)?.url}
        />
      ) : null}
      {fetchingSuccessful && jobOpportunities.length && countrySupported ? (
        <>
          <JobListHeader jobNumber={totalNumber} countrySupported={countrySupported} />
          <div className="JobList__list">
            <InfiniteScroll
              dataLength={jobOpportunities.length}
              next={() => {
                store.fetchJobList({
                  page: page + 1,
                  perPage,
                  countryCode,
                  selectedRegions,
                  selectedJobFunctions,
                  sortBy,
                  sortDir
                });

                setPage(page + 1);
              }}
              hasMore={totalNumber > jobOpportunities.length}
            >
              {jobItemList}
            </InfiniteScroll>
          </div>
          <footer>
            <Footer landingsUrl={landingsUrl} />
          </footer>
        </>
      ) : null}
    </div>
  );
};

JobList.propTypes = {
  jobOpportunity: PropTypes.object
};

JobList.defaultProps = {
  jobOpportunity: {}
};

export default JobList;
