import React from 'react';
import i18n from 'lib/i18n';
import PropTypes from 'prop-types';
import closeCircleIcon from 'Common/images/close-circle.svg';
import searchIcon from 'Common/images/search.svg';
import Checkbox from '../checkbox/checkbox.component';
import DropdownFilterSingleOption from '../dropdown-filter-single-option/dropdown-filter-single-option.component';
import Button from '../button/button.component';

const DropdownFilterContent = ({
  heading,
  headingAction,
  placeholder,
  selectAllLabel,
  selectedItems,
  name,
  onSelectAll,
  items,
  multi,
  onChange,
  disableSubmit,
  disableClearAll,
  clearAll,
  onApply,
  includeTextSearch
}) => {
  const [textFilter, setTextFilter] = React.useState('');

  return (
    <>
      <div className="DropdownFilter__heading">
        {headingAction}
        {heading}
      </div>
      {includeTextSearch ? (
        <div className="DropdownFIlter__text-search">
          <img className="DropdownFilter__text-search-icon" src={searchIcon} alt="" />
          <input
            type="text"
            value={textFilter}
            placeholder={placeholder || i18n.t('jobfeed.filters.categories_placeholder')}
            onChange={event => setTextFilter(event.currentTarget.value)}
          />
          {textFilter !== '' && (
            <button
              className="DropdownFilter__text-search-clear"
              onClick={() => setTextFilter('')}
              type="button"
            >
              <img
                className="DropdownFilter__text-search-clear-icon"
                src={closeCircleIcon}
                alt=""
              />
            </button>
          )}
        </div>
      ) : null}
      <div className="DropdownFilter__items">
        {selectAllLabel && (
          <Checkbox
            text={selectAllLabel}
            checked={selectedItems.length === 0}
            value="all"
            name={name}
            onChange={onSelectAll}
            className="DropdownFilter__select-all"
          />
        )}
        {items
          .filter(({ label }) => label?.toLowerCase().indexOf(textFilter?.toLowerCase()) > -1)
          .map(({ value, label }) => {
            return multi ? (
              <Checkbox
                key={label}
                checked={selectedItems.some(slug => slug === value)}
                text={label}
                value={value}
                name={name}
                onChange={onChange}
              />
            ) : (
              <DropdownFilterSingleOption
                key={label}
                checked={selectedItems.some(slug => slug === value)}
                text={label}
                value={value}
                name={name}
                onChange={onChange}
              />
            );
          })}
      </div>
      {!disableSubmit || !disableClearAll ? (
        <div className="DropdownFilter__actions">
          {!disableClearAll ? (
            <Button size="small" variant="tertiary" onClick={clearAll}>
              {i18n.t('jobfeed.filters.clear_all')}
            </Button>
          ) : null}
          {!disableSubmit ? (
            <Button
              size="small"
              onClick={() => onApply(multi ? selectedItems : selectedItems.pop())}
            >
              {i18n.t('jobfeed.filters.apply')}
            </Button>
          ) : null}
        </div>
      ) : null}
    </>
  );
};

DropdownFilterContent.propTypes = {
  heading: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  onApply: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ),
  selectedItems: PropTypes.arrayOf(PropTypes.string),
  selectAllLabel: PropTypes.string,
  disableClearAll: PropTypes.bool,
  disableSubmit: PropTypes.bool,
  multi: PropTypes.bool,
  headingAction: PropTypes.node,
  onSelectAll: PropTypes.func,
  onChange: PropTypes.func,
  clearAll: PropTypes.func,
  includeTextSearch: PropTypes.bool
};

DropdownFilterContent.defaultProps = {
  heading: '',
  placeholder: '',
  name: 'dropdown',
  onApply: () => undefined,
  items: [],
  selectedItems: [],
  selectAllLabel: null,
  disableClearAll: false,
  disableSubmit: false,
  multi: true,
  headingAction: null,
  onSelectAll: () => undefined,
  onChange: () => undefined,
  clearAll: () => undefined,
  includeTextSearch: false
};

export default DropdownFilterContent;
