import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { SnackBar } from '@jobandtalent/design-system';

import I18n from 'lib/i18n';
import ResetPassword from 'Jobfeed/containers/reset-password/reset-password.container';
import { useStore } from 'Jobfeed/store/hook';

import './session_options.container.css.less';

const SessionOptions = ({ openPasswordResetOnLoad, resetPasswordToken, email, source }) => {
  const store = useStore();
  const focusOn = store.getFocusOn();
  const [isResetPasswordVisible, setResetPasswordVisiblity] = useState(openPasswordResetOnLoad);
  const [isResetPasswordSuccessfull, setResetPasswordSuccessfull] = useState(false);

  const closeResetPassword = (successful = false) => {
    setResetPasswordSuccessfull(successful);
    setResetPasswordVisiblity(false);
  };

  const focusOffMode = (
    <div className="SessionOptions">
      {isResetPasswordVisible && (
        <ResetPassword
          token={resetPasswordToken}
          email={email}
          closeResetPassword={closeResetPassword}
          source={source}
        />
      )}
      {isResetPasswordSuccessfull && (
        <SnackBar>{I18n.t('jobfeed.password_reset.password.success')}</SnackBar>
      )}
    </div>
  );

  return focusOn ? <></> : focusOffMode;
};

SessionOptions.propTypes = {
  openPasswordResetOnLoad: PropTypes.bool,
  resetPasswordToken: PropTypes.string,
  email: PropTypes.string,
  source: PropTypes.string
};

SessionOptions.defaultProps = {
  openPasswordResetOnLoad: false,
  resetPasswordToken: null,
  email: null,
  source: null
};

export default SessionOptions;
