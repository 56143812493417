const footerLinksData = [
  { key: 'jobfeed.footer.text_links.company.links.about_us', relative: true },
  { key: 'jobfeed.footer.text_links.company.links.careers', relative: true },
  { key: 'jobfeed.footer.text_links.product.links.help_center', relative: false }
];

const legalLinksData = {
  columnKey: 'jobfeed.footer.text_links.legal.title',
  links: [
    { key: 'jobfeed.footer.text_links.legal.links.terms_of_service', relative: true },
    { key: 'jobfeed.footer.text_links.legal.links.privacy', relative: true },
    { key: 'jobfeed.footer.text_links.legal.links.cookies', relative: true },
    { key: 'jobfeed.footer.text_links.legal.links.impressum', relative: true }
  ]
};

const downloadLinksData = {
  columnKey: 'jobfeed.footer.icon_links.downloads.title',
  links: [
    {
      key: 'jobfeed.footer.icon_links.downloads.links.apple'
    },
    {
      key: 'jobfeed.footer.icon_links.downloads.links.play_store'
    }
  ]
};

export default { footerLinksData, legalLinksData, downloadLinksData };
