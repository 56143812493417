/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import I18njs from 'i18n-js';
import moment from 'moment';
import merge from 'lodash.merge';

// Override default behavior when a translation is missing.
I18njs.missingTranslation = () => null;

export default class I18n {
  static addedTranslations = new Set();

  static defaultLocale = 'en-GB';

  static locale = 'en-GB';

  static ready = false;

  static testMode = false;

  static testModeHasFallbackValue = true;

  static moment = moment;

  static addTranslation(book) {
    this.addedTranslations.add(book);
  }

  static async loadTranslation(book, locale, retry) {
    try {
      const translations = require(`Locales/${book}.${locale}.yml`);

      I18njs.translations[locale] = merge(I18njs.translations[locale], translations[locale]);
    } catch (error) {
      if (retry) {
        I18n.loadTranslation(book, I18n.defaultLocale, false);
      } else {
        throw new Error(error);
      }
    }
  }

  static getCurrentLanguage() {
    return I18n.locale.substring(0, 2);
  }

  static setLocale(locale = I18n.defaultLocale, retry = true) {
    I18n.ready = false;

    I18n.locale = locale;
    I18njs.locale = locale;
    I18njs.defaultLocale = I18n.defaultLocale;
    I18n.moment.locale(I18n.momentLocaleFor(locale));

    const promises = Array.from(this.addedTranslations).map(book => {
      return I18n.loadTranslation(book, locale, retry);
    });

    I18n.ready = Promise.all(promises);
  }

  static date(date, params = { format: 'short' }) {
    return I18njs.l(`date.formats.${params.format}`, date);
  }

  static t(key, params = {}) {
    const translateFunction = I18n.testMode ? I18n.translateForTest : I18n.translateWithI18njs;
    return translateFunction(key, params);
  }

  static toCurrency(amount, options = {}) {
    return I18njs.toCurrency(amount, Object.assign({ format: '%u %n' }, options));
  }

  static toHumanSize(size) {
    return I18njs.toHumanSize(size);
  }

  static toNumber(number, options = {}) {
    return I18njs.toNumber(number, options);
  }

  static translateWithI18njs(key, params) {
    if (!I18n.ready) {
      throw new Error('load translation before trying to use "translate" function');
    }
    return I18njs.t(key, params);
  }

  static momentLocaleFor(locale) {
    return I18n.localeSupportedByMoment(locale)
      ? locale.toLocaleLowerCase()
      : locale.substring(0, 2);
  }

  static localeSupportedByMoment(locale) {
    return locale === 'en-GB';
  }

  static translateForTest(key, params) {
    const fullKey = [params.scope, key].filter(item => !!item).join('.');
    const paramValues = Object.entries(params)
      .filter(kv => kv[0] !== 'scope')
      .map(kv => `${kv[0]}=${kv[1]}`);
    const fallbackTestValue = `__${[fullKey, ...paramValues].join('|')}__`;
    const defaultValue =
      I18njs.translations[I18njs.locale][fullKey] ||
      (I18n.testModeHasFallbackValue ? fallbackTestValue : null);
    return I18njs.t(key, Object.assign({ defaultValue }, params));
  }

  static addTranslations(locale, newTranslations) {
    I18njs.translations = {
      [locale]: Object.assign({ ...I18njs.translations[locale] }, { ...newTranslations })
    };
  }
}
