import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import I18n from 'lib/i18n';
import { flush } from '@amplitude/analytics-browser';
import { Button } from '@jobandtalent/design-system';

import QRCode from 'qrcode';

import './reset-password-form.component.css.less';
import appStoreImage from 'Jobfeed/assets/app-store-button.png';
import appStoreImage2x from 'Jobfeed/assets/app-store-button-2x.png';
import googlePlayImage from 'Jobfeed/assets/google-play-button.png';
import googlePlayImage2x from 'Jobfeed/assets/google-play-button-2x.png';
import checkIcon from 'Images/check_icon.png';
import { useSelector } from 'react-redux';
import { UAParser } from 'ua-parser-js';
import { GlobalContext } from 'Jobfeed/context/GlobalContext';
import { FirebaseDynamicLinks } from 'firebase-dynamic-links';
import { useStore } from 'Jobfeed/store/hook';
import DeeplinksService from 'Common/services/deeplinks.service';
import { trackResetPassword } from './track';

const ResetPasswordSuccess = ({ email }) => {
  const parser = new UAParser();
  const deeplinksService = new DeeplinksService();
  const [imageSrc, setImageSrc] = useState(null);
  const [deeplink, setDeeplink] = useState(null);
  const country = useSelector(state => state.jobfeed.countryCode || '');
  const globalContext = React.useContext(GlobalContext);
  const store = useStore();
  const countryCode = store.getCountryCode();
  const queryParams = store.getQueryParams();
  const appStoreLinksVisible = countryCode?.toLowerCase() !== 'us';

  const handleAppLink = () => {
    window.location.replace(deeplink);
  };

  const appStore = {
    link: deeplinksService.appleStore(),
    logo: appStoreImage,
    logo2x: appStoreImage2x
  };

  const googlePlay = {
    link: deeplink,
    logo: googlePlayImage,
    logo2x: googlePlayImage2x
  };

  const generateQR = async text => {
    try {
      const qrCode = await QRCode.toDataURL(text);

      return qrCode;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }

    return null;
  };

  useEffect(() => {
    const fetchDeepLink = async () => {
      const firebaseDynamicLinks = new FirebaseDynamicLinks(globalContext.firebaseApiKeyPublic);

      const link = `${globalContext.firebaseMainDomain}/login/${email}?country_code=${country}`;
      const encodedLink = encodeURIComponent(link);
      const result = await firebaseDynamicLinks.createLink({
        longDynamicLink: `${globalContext.firebaseDynamicLinkDomain}/?link=${encodedLink}&apn=${globalContext.firebaseApn}&ibi=${globalContext.firebaseIbi}&isi=${globalContext.firebaseIsi}`,
        suffix: {
          option: 'UNGUESSABLE'
        }
      });
      setDeeplink(result.shortLink);
    };

    if (
      parser.getOS().name === 'Android' ||
      (parser.getOS().name === 'iOS' && parseFloat(parser.getOS().version) < 16)
    ) {
      fetchDeepLink();
    } else {
      setDeeplink(deeplinksService.appleStore());
    }
  }, []);

  useEffect(() => {
    const getQRSrc = async () => {
      const qrSrc = await generateQR(deeplinksService.qrCodeDeeplink());

      setImageSrc(qrSrc);
    };

    getQRSrc();
  }, []);

  useEffect(() => {
    const extraParams = {
      country_code: countryCode?.toLowerCase()
    };

    if (queryParams?.get('hash')) {
      extraParams.token = queryParams.get('hash');
    }

    trackResetPassword('set_password_success_webiste_visited', queryParams, extraParams);

    flush();
  }, []);

  const trackAppStoreClick = downloadMethod => {
    const extraParams = {
      country_code: countryCode?.toLowerCase(),
      download_method: downloadMethod
    };

    if (queryParams?.get('hash')) {
      extraParams.token = queryParams.get('hash');
    }

    trackResetPassword('download_app_button_clicked', queryParams, extraParams);

    flush();
  };

  return (
    <div className="ResetPassword__success">
      <div className="circle-check">
        <img className="circle-check__icon" src={checkIcon} alt="" />
      </div>
      <p className="after-reset-instructions">
        {I18n.t('jobfeed.password_reset.password.success_screen.message')}
      </p>
      <p className="after-reset-email-presenter">{email}</p>
      <div className="phone-frame">
        <div className="phone-frame__notch" />
        <div className="qr-code">
          <img src={imageSrc} alt="" />
        </div>
        <p className="qr-code-instructions">
          {I18n.t('jobfeed.password_reset.password.success_screen.desktop_instructions')}
        </p>
        {appStoreLinksVisible && (
          <>
            <div className="app-links">
              <a href={appStore.link} onClick={() => trackAppStoreClick('ios_store')}>
                <img
                  src={appStore.logo}
                  alt="Link to Apple's App Store"
                  srcSet={`${appStore.logo}, ${appStore.logo2x} 2x`}
                />
              </a>
              <a href={appStore.link} onClick={() => trackAppStoreClick('android_store')}>
                <img
                  src={googlePlay.logo}
                  srcSet={`${googlePlay.logo}, ${googlePlay.logo2x} 2x`}
                  alt="Link to Google Play"
                />
              </a>
            </div>
            <a className="continue-on-desktop" href={`/${country.toLowerCase()}`}>
              {I18n.t('jobfeed.password_reset.password.success_screen.desktop_continue')}
            </a>
          </>
        )}
      </div>
      <Button
        className="ResetPassword__success__submit-button ResetPassword__success__submit-button-primary"
        disabled={deeplink === null}
        onSelect={() => {
          trackAppStoreClick('download_button');
          handleAppLink();
        }}
      >
        {I18n.t('jobfeed.password_reset.password.success_screen.cta')}
      </Button>
    </div>
  );
};

ResetPasswordSuccess.propTypes = {
  email: PropTypes.string
};

ResetPasswordSuccess.defaultProps = {
  email: null
};

export default ResetPasswordSuccess;
