import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import I18n from 'lib/i18n';
import closeIcon from 'Common/images/close.svg';
// Stylesheets
import './job-list-header.component.css.less';
import { FiltersContext } from 'Jobfeed/context/FiltersContext';
import DropdownFilter from 'Common/components/dropdown-filter/dropdown-filter.component';
import { useStore } from 'Jobfeed/store/hook';

const JobListHeader = props => {
  const sortItems = [
    { value: 'start_date:asc', label: I18n.t('jobfeed.sort.start_date') },
    { value: 'created_at:desc', label: I18n.t('jobfeed.sort.newest') }
  ];

  const filtersContext = React.useContext(FiltersContext);
  const store = useStore();
  const sortBy = store.getSortBy();
  const sortDir = store.getSortDir();
  const selectedSortLabel = sortItems.find(item => item.value === `${sortBy}:${sortDir}`).label;

  const Trigger = React.forwardRef((triggerProps, ref) => (
    <button type="button" className="JobListHeader__sort-button" {...triggerProps} ref={ref}>
      {selectedSortLabel}
    </button>
  ));

  Trigger.displayName = 'Trigger';

  const onApply = sort => {
    const [newSortBy, newSortDir] = sort.split(':');

    store.setSort(newSortBy, newSortDir);

    filtersContext.closeFilter();
  };

  return (
    <div
      className={classnames('JobListHeader', {
        'JobListHeader--backdrop': filtersContext?.visible,
        'JobListHeader--not-supported': !props.countrySupported
      })}
    >
      <div className="JobListHeader__text">
        {props.countrySupported && (
          <>
            <h1 className="JobListHeader__title">
              <span className="JobListHeader__title-count">{props.jobNumber}</span>
              {I18n.t('jobfeed.job_list.openings')}
            </h1>
          </>
        )}
        <div className="JobListHeader__sort">
          <span>{I18n.t('jobfeed.sort.label')}:</span>
          <DropdownFilter
            simple
            TriggerElement={Trigger}
            heading="Sort by"
            name="sort"
            items={sortItems}
            preselectedItems={[`${sortBy}:${sortDir}`]}
            onApply={onApply}
            disableClearAll
            disableSubmit
            multi={false}
            headingAction={
              <button
                type="button"
                onClick={() => filtersContext.closeFilter()}
                className="DropdownFilter__heading-action DropdownFilter__heading-action--mobile"
              >
                <img className="DropdownFilter__trigger-icon" src={closeIcon} alt="" />
              </button>
            }
          />
        </div>
      </div>
    </div>
  );
};

JobListHeader.propTypes = {
  jobNumber: PropTypes.number.isRequired,
  countrySupported: PropTypes.bool.isRequired
};
export default JobListHeader;
