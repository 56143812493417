import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { track, getDeviceId } from '@amplitude/analytics-browser';
import { Helmet } from 'react-helmet';

// Containers
import JobDetail from 'Jobfeed/containers/job-detail/job-detail.component';
import { JobOpportunityContextProvider } from 'Jobfeed/context/JobOpeningContext';
import { getUTMData } from 'Jobfeed/services/tracking.service';
import getJobPostingSchema from 'Jobfeed/services/head.service';
import { GlobalContext } from 'Jobfeed/context/GlobalContext';

const JobDetailView = ({ jobOpportunity }) => {
  const globalContext = React.useContext(GlobalContext);

  useEffect(() => {
    track('job_details_viewed', {
      job_opportunity_id: jobOpportunity.id,
      from_share: false,
      is_expired: false,
      ...getUTMData()
    }).promise.then(() => {
      globalContext.setAmplitudeDeviceId(getDeviceId());
    });

    if (document.querySelector('[type="application/ld+json"]')) {
      document.querySelector('[type="application/ld+json"]').remove();
    }
  }, []);

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`${JSON.stringify(getJobPostingSchema(jobOpportunity))}`}
        </script>
      </Helmet>
      <JobOpportunityContextProvider>
        <JobDetail jobOpportunity={jobOpportunity} />
      </JobOpportunityContextProvider>
    </>
  );
};

JobDetailView.propTypes = {
  jobOpportunity: PropTypes.object
};

JobDetailView.defaultProps = {
  jobOpportunity: {}
};

export default JobDetailView;
