import React from 'react';
import { track } from '@amplitude/analytics-browser';
import i18n from 'lib/i18n';
import { useStore } from 'Jobfeed/store/hook';
import { FiltersContext } from 'Jobfeed/context/FiltersContext';
import closeIcon from 'Common/images/close.svg';
import CountriesService from 'Common/services/countries.service';
import DropdownFilter from '../dropdown-filter/dropdown-filter.component';

const CountryFilter = () => {
  const countriesServices = new CountriesService();
  const filtersContext = React.useContext(FiltersContext);
  const store = useStore();
  const countryCode = store.getCountryCode();
  const selectedCountryName = countriesServices.countryName(countryCode);
  const selectedCountryLabel = i18n.t(`jobfeed.countries.${selectedCountryName}`);

  const items = countriesServices.listCountries().map(({ name, code }) => {
    return { value: code, label: i18n.t(`jobfeed.countries.${name}`) };
  });

  const onApply = slug => {
    store.changeCountry(slug);

    track('filters_applied', {
      action: 'apply',
      number_categories: 0,
      number_regions: 0,
      filter_location_country_values: slug,
      filter_location_region_values: [],
      filter_category_values: []
    });

    filtersContext.closeFilter();
  };

  return (
    <DropdownFilter
      triggerText={selectedCountryLabel}
      heading={i18n.t('jobfeed.filters.choose_country')}
      name="country"
      items={items}
      preselectedItems={[countryCode]}
      onApply={onApply}
      disableClearAll
      disableSubmit
      multi={false}
      headingAction={
        <button
          type="button"
          onClick={() => filtersContext.closeFilter()}
          className="DropdownFilter__heading-action DropdownFilter__heading-action--mobile"
        >
          <img className="DropdownFilter__trigger-icon" src={closeIcon} alt="" />
        </button>
      }
    />
  );
};

export default CountryFilter;
