import PropTypes from 'prop-types';
import I18n from 'lib/i18n';

export const Translation = ({ name, children }) => {
  const translation = I18n.t(name);

  if (translation) {
    return children(translation);
  }

  return null;
};

Translation.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired
};

export default Translation;
