import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  shift,
  useFloating,
  useInteractions,
  useRole,
  useDismiss
} from '@floating-ui/react';

const Dropdown = ({ children, triggerTitle }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const { x, y, refs, strategy, context } = useFloating({
    placement: 'bottom-start',
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      offset({
        mainAxis: 8
      }),
      flip(),
      shift({ padding: 8 })
    ],
    whileElementsMounted: autoUpdate
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useRole(context, { role: 'tooltip' }),
    useDismiss(context)
  ]);

  return (
    <>
      <button
        className="Footer__dropdown-trigger"
        onClick={setIsOpen}
        type="button"
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        {triggerTitle}
      </button>
      <FloatingPortal>
        <div
          className={classnames('Footer__dropdown-content', {
            'Footer__dropdown-content--open': isOpen
          })}
          ref={refs.setFloating}
          style={{
            position: strategy,
            zIndex: 10,
            top: y ?? 0,
            left: x ?? 0
          }}
          {...getFloatingProps()}
        >
          {children}
        </div>
      </FloatingPortal>
    </>
  );
};

Dropdown.propTypes = {
  children: PropTypes.node.isRequired,
  triggerTitle: PropTypes.string.isRequired
};

export default Dropdown;
