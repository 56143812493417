import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { useDebounce } from 'react-use';

// Stylesheets
import './filters-bar.component.css.less';
import { FiltersContext } from 'Jobfeed/context/FiltersContext';
import { GlobalContext } from 'Jobfeed/context/GlobalContext';

const FiltersBar = ({ children }) => {
  const globalContext = React.useContext(GlobalContext);
  const filtersContext = React.useContext(FiltersContext);

  const [showLeftArrow, setShowLeftArrow] = React.useState(false);
  const [showRightArrow, setShowRightArrow] = React.useState(true);
  const [hasScrollArea, setHasScrollArea] = React.useState(false);
  const [val, setVal] = React.useState(0);
  const scrollAreaRef = React.useRef(null);

  // Scroll event handler function
  const scrollFeatureHandler = () => {
    const scrollWidth = scrollAreaRef?.current?.scrollWidth ?? 0;
    const clientWidth = scrollAreaRef?.current?.clientWidth ?? 0;
    const scrollLeft = Math.round(scrollAreaRef?.current?.scrollLeft ?? 0); // Get the scroll position

    // Calculate the maximum scroll position to consider as scrolled completely to the right
    const maxScrollLeft = scrollWidth - clientWidth;

    // 0 maxScrollLeft means there is no scroll area
    setHasScrollArea(!!maxScrollLeft);

    // Show/hide the left arrow
    setShowLeftArrow(!!scrollLeft);

    // Check if scrolled completely to the right
    setShowRightArrow(scrollLeft < maxScrollLeft);
  };

  // To use react-use debounced function we need to track a changing value
  function handleScroll(event) {
    setVal(event.target.scrollLeft);
  }

  // Also for the window resize event we need to track a changing value
  function handleResize() {
    setVal(window.innerWidth);
  }

  // Debounce the scroll feature
  useDebounce(scrollFeatureHandler, 100, [val]);

  React.useEffect(() => {
    const scrollAreaElement = scrollAreaRef.current;
    if (scrollAreaElement) {
      scrollAreaElement.addEventListener('scroll', handleScroll);
      window.addEventListener('resize', handleResize);
      return () => {
        scrollAreaElement.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', handleResize);
      };
    }

    return () => {};
  }, []);

  React.useEffect(() => {
    if (filtersContext?.shouldDisplayBackdrop) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [filtersContext?.shouldDisplayBackdrop]);

  return (
    <div
      className={classnames('FiltersBar', {
        'FiltersBar--has-scroll-area': hasScrollArea,
        'FiltersBar--has-jo-selected': globalContext?.hasJOSelected
      })}
    >
      {hasScrollArea && showLeftArrow && <span className="FiltersBar__left-shadow" />}
      <div className="FiltersBar__wrapper" ref={scrollAreaRef}>
        {children}
      </div>
      {hasScrollArea && showRightArrow && <span className="FiltersBar__right-shadow" />}
    </div>
  );
};

FiltersBar.propTypes = {
  children: PropTypes.node
};

FiltersBar.defaultProps = {
  children: null
};

export default FiltersBar;
