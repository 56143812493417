export const FETCH_JOB_LIST_REQUEST = 'FETCH_JOB_LIST_REQUEST';
export const FETCH_JOB_LIST_SUCCESS = 'FETCH_JOB_LIST_SUCCESS';
export const FETCH_JOB_LIST_FAILURE = 'FETCH_JOB_LIST_FAILURE';

export const FETCH_JOB_DETAIL_REQUEST = 'FETCH_JOB_DETAIL_REQUEST';
export const FETCH_JOB_DETAIL_SUCCESS = 'FETCH_JOB_DETAIL_SUCCESS';
export const FETCH_JOB_DETAIL_FAILURE = 'FETCH_JOB_DETAIL_FAILURE';

export const FETCH_JOB_FUNCTIONS_REQUEST = 'FETCH_JOB_FUNCTIONS_REQUEST';
export const FETCH_JOB_FUNCTIONS_SUCCESS = 'FETCH_JOB_FUNCTIONS_SUCCESS';
export const FETCH_JOB_FUNCTIONS_FAILURE = 'FETCH_JOB_FUNCTIONS_FAILURE';

export const FETCH_REGIONS_REQUEST = 'FETCH_REGIONS_REQUEST';
export const FETCH_REGIONS_SUCCESS = 'FETCH_REGIONS_SUCCESS';
export const FETCH_REGIONS_FAILURE = 'FETCH_REGIONS_FAILURE';

export const ADD_SELECTED_REGION = 'ADD_SELECTED_REGION';
export const DELETE_SELECTED_REGION = 'DELETE_SELECTED_REGION';
export const UPDATE_SELECTED_REGIONS = 'UPDATE_SELECTED_REGIONS';

export const ADD_SELECTED_JOB_FUNCTION = 'ADD_SELECTED_JOB_FUNCTION';
export const DELETE_SELECTED_JOB_FUNCTION = 'DELETE_SELECTED_JOB_FUNCTION';
export const UPDATE_SELECTED_JOB_FUNCTIONS = 'UPDATE_SELECTED_JOB_FUNCTIONS';

export const RESET_FILTERS = 'RESET_FILTERS';

export const CHANGE_COUNTRY = 'CHANGE_COUNTRY';

export const FETCH_GEOLOCATION_MAP_URL_SIGNATURE_SUCCESS =
  'FETCH_GEOLOCATION_MAP_URL_SIGNATURE_SUCCESS';
export const FETCH_GEOLOCATION_MAP_URL_SIGNATURE_FAILURE =
  'FETCH_GEOLOCATION_MAP_URL_SIGNATURE_FAILURE';

export const DISABLE_FOCUS_ON = 'DISABLE_FOCUS_ON';

export const CHANGE_SORT = 'CHANGE_SORT';
