import React from 'react';
import PropTypes from 'prop-types';
import notSupportedImage from '../../assets/blank-state-search.svg';

// Stylesheets
import './country-not-supported.css.less';

const CountryNotSupported = ({ url }) => {
  return (
    <div className="CountryNotSupported">
      <div className="CountryNotSupported__wrapper">
        <img alt="" src={notSupportedImage} />
        <p className="CountryNotSupported__text">Our jobs are published on a different website.</p>
        <a className="ds-atom-btn-small-primary-normal" href={url}>
          Continue to website
        </a>
      </div>
    </div>
  );
};

CountryNotSupported.propTypes = {
  url: PropTypes.string
};

CountryNotSupported.defaultProps = {
  url: ''
};

export default CountryNotSupported;
