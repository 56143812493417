import React, { useContext, useEffect, useState } from 'react';
import { IconSvg } from '@jobandtalent/design-system';
import I18n from 'lib/i18n';
import QRCode from 'qrcode';
import { track, flush } from '@amplitude/analytics-browser';

// Stylesheets
import './job-apply-now.component.css.less';
import { JobOpportunityContext } from 'Jobfeed/context/JobOpeningContext';
import appStoreImage from 'Jobfeed/assets/app-store-button.png';
import appStoreImage2x from 'Jobfeed/assets/app-store-button-2x.png';
import googlePlayImage from 'Jobfeed/assets/google-play-button.png';
import googlePlayImage2x from 'Jobfeed/assets/google-play-button-2x.png';

const JobApplyNow = () => {
  const jobOpportunityContext = useContext(JobOpportunityContext);
  const [imageSrc, setImageSrc] = useState(null);

  const appStore = {
    link: 'https://apps.apple.com/gb/app/jobandtalent/id665060895',
    logo: appStoreImage,
    loxo2x: appStoreImage2x
  };

  const googlePlay = {
    link: 'https://play.google.com/store/apps/details?id=com.jobandtalent.android',
    logo: googlePlayImage,
    logo2x: googlePlayImage2x
  };

  const generateQR = async text => {
    try {
      const qrCode = await QRCode.toDataURL(text, {
        width: 172
      });

      return qrCode;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }

    return null;
  };

  useEffect(() => {
    const getQRSrc = async () => {
      const qrSrc = await generateQR(jobOpportunityContext.deeplink);

      setImageSrc(qrSrc);
    };

    if (jobOpportunityContext.deeplink?.length > 0) {
      getQRSrc();
    }
  }, [jobOpportunityContext.deeplink]);

  return (
    <>
      <div
        className="JobApplyNow__backdrop"
        onClick={jobOpportunityContext?.hideApplyNow}
        role="button"
        tabIndex={0}
        onKeyDown={event => {
          if (event.key === 'Escape' && jobOpportunityContext) {
            jobOpportunityContext.hideApplyNow();
          }
        }}
      />
      <div className="JobApplyNow">
        <button
          type="button"
          className="JobApplyNow__close"
          onClick={jobOpportunityContext?.hideApplyNow}
        >
          <IconSvg className="ds-org-modal-close-icon" icon="cross" />
        </button>
        <div className="JobApplyNow__title">{I18n.t('jobfeed.apply_modal.get_job')}</div>
        <div className="JobApplyNow__content">
          <div className="JobApplyNow__QR">
            <img src={imageSrc} alt="" />
          </div>
          <div className="JobApplyNow__text">{I18n.t('jobfeed.apply_modal.scan_code')}</div>
          <div className="JobApplyNow__buttons">
            <button
              type="button"
              onClick={() => {
                track('download_app_requested', {
                  store: 'ios'
                });
                flush();

                window.location.href = appStore.link;
              }}
              className="JobApplyNow__button"
            >
              <img src={appStore.logo} alt="" srcSet={`${appStore.logo}, ${appStore.loxo2x} 2x`} />
            </button>
            <button
              type="button"
              onClick={() => {
                track('download_app_requested', {
                  store: 'android'
                });
                flush();

                window.location.href = googlePlay.link;
              }}
              className="JobApplyNow__button"
            >
              <img
                src={googlePlay.logo}
                srcSet={`${googlePlay.logo}, ${googlePlay.logo2x} 2x`}
                alt=""
              />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

JobApplyNow.propTypes = {};
JobApplyNow.defaultProps = {};

export default JobApplyNow;
