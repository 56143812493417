import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import logoRouteSVG from 'Common/images/logo.svg';
import { useSelector } from 'react-redux';
import { useStore } from 'Jobfeed/store/hook';

// Stylesheets
import './navbar.component.css.less';
import { FiltersContext } from 'Jobfeed/context/FiltersContext';

const Navbar = ({ className, children }) => {
  const filterContext = React.useContext(FiltersContext);
  const store = useStore();
  const country = useSelector(state => state.jobfeed.countryCode || '');
  const focusOn = store.getFocusOn();
  const ccTLDs = {
    CO: 'co',
    ES: 'es',
    GB: 'co.uk',
    SE: 'se',
    US: 'com'
  };

  const hideFilters = () => {
    return filterContext?.visible
      ? filterContext?.hideFilters(state => {
          store.changeCountry(state.savedCountry);
          store.updateSelectedJobFunctions(state.selectedJobFunctions);
          store.updateSelectedRegions(state.selectedRegions);
        })
      : false;
  };

  return (
    <div
      className={classnames('Navbar', className, {
        'Navbar--backdrop': filterContext?.visible
      })}
      onClick={hideFilters}
      onKeyDown={event => {
        if (event.key === 'Enter') {
          hideFilters();
        }
      }}
      role="button"
      tabIndex={0}
    >
      <div className="Navbar__wrap">
        <div className="Navbar__container">
          {focusOn ? (
            <picture>
              <img src={logoRouteSVG} className="Navbar__logo" alt="Job&Talent logo" />
            </picture>
          ) : (
            <a href={`https://www.jobandtalent.${ccTLDs[country]}`}>
              <picture>
                <img src={logoRouteSVG} className="Navbar__logo" alt="Job&Talent logo" />
              </picture>
            </a>
          )}
          <div className="Navbar__content">{children}</div>
        </div>
      </div>
    </div>
  );
};

Navbar.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

Navbar.defaultProps = {
  className: '',
  children: null
};

export default Navbar;
