import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'lib/i18n';
import classnames from 'classnames';

import pinSvg from 'Images/pin_slim.svg';
import calendarSvg from 'Images/calendar_slim.svg';
import personSvg from 'Images/person_slim.svg';

// Stylesheets
import './job-item.component.css.less';

const getCompanyName = jobOpportunity => {
  let { companyName } = jobOpportunity;

  if (jobOpportunity.hideCompanyInfo) {
    companyName = I18n.t('jobfeed.job_detail.company_hidden', {
      location: jobOpportunity?.geodatum?.formattedHiddenLocation
    });
  }

  return companyName;
};

const JobItem = ({ jobOpportunity, onAction, className, href }) => {
  const hasExtraPay =
    jobOpportunity.payRate?.otherHours?.length > 0 ||
    jobOpportunity.payRate?.other_hours?.length > 0 ||
    jobOpportunity.payRate?.variablePay?.length > 0 ||
    jobOpportunity.payRate?.variable_pay?.length > 0 ||
    jobOpportunity.payRate?.expenses?.length > 0;

  return (
    <a className={`JobItem ${className}`} href={href} onClick={onAction}>
      <div className="JobItem__what-how-much">
        <div className="JobItem__company">{getCompanyName(jobOpportunity)}</div>
        <div className="JobItem__job-title">{jobOpportunity.title}</div>
        <div className="JobItem__salary">
          <div className="JobItem__pay">{jobOpportunity.salary}</div>
          {hasExtraPay && <div className="JobItem__extras">+ extras</div>}
        </div>
      </div>
      <div className="JobItem__where-when">
        {jobOpportunity?.geodatum?.locality ? (
          <div className="JobItem__city">
            <img src={pinSvg} alt="" />
            {jobOpportunity?.geodatum?.locality}
          </div>
        ) : null}
        <div className="JobItem__start">
          <img src={calendarSvg} alt="" />
          {jobOpportunity.startDate}
        </div>
        <div className="JobItem__vacancies">
          <img src={personSvg} alt="" />
          <div
            className={classnames('JobItem__vacancies-left', {
              'JobItem__vacancies-left--highlighted': jobOpportunity.vacanciesLeft <= 5
            })}
          >
            {I18n.t('jobfeed.job_detail.vacancies_left', {
              count: jobOpportunity.vacanciesLeft
            })}
          </div>
          <div className="JobItem__vacancies-total">
            {I18n.t('jobfeed.job_detail.vacancies_total', {
              count: jobOpportunity.vacanciesTotal
            })}
          </div>
        </div>
      </div>
    </a>
  );
};

JobItem.propTypes = {
  jobOpportunity: PropTypes.object.isRequired,
  onAction: PropTypes.func.isRequired,
  className: PropTypes.string,
  href: PropTypes.string
};

JobItem.defaultProps = {
  className: '',
  href: ''
};

export default JobItem;
