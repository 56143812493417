// Actions
import { LOCATION_CHANGE } from 'connected-react-router';
import {
  FETCH_JOB_LIST_REQUEST,
  FETCH_JOB_LIST_SUCCESS,
  FETCH_JOB_LIST_FAILURE,
  FETCH_JOB_DETAIL_REQUEST,
  FETCH_JOB_DETAIL_SUCCESS,
  FETCH_JOB_DETAIL_FAILURE,
  FETCH_REGIONS_REQUEST,
  FETCH_REGIONS_SUCCESS,
  FETCH_JOB_FUNCTIONS_REQUEST,
  FETCH_JOB_FUNCTIONS_SUCCESS,
  ADD_SELECTED_REGION,
  DELETE_SELECTED_REGION,
  UPDATE_SELECTED_REGIONS,
  ADD_SELECTED_JOB_FUNCTION,
  DELETE_SELECTED_JOB_FUNCTION,
  UPDATE_SELECTED_JOB_FUNCTIONS,
  RESET_FILTERS,
  CHANGE_COUNTRY,
  FETCH_GEOLOCATION_MAP_URL_SIGNATURE_SUCCESS,
  DISABLE_FOCUS_ON,
  CHANGE_SORT
} from 'Jobfeed/store/actions_names';

export const JOBFEED_DEFAULT_STATE = {
  jobOpportunities: [],
  jobOpportunity: {},
  totalNumber: 0,
  perPage: 25,
  jobList: {
    loading: true,
    firstLoad: true,
    fetchingSuccessful: true
  },
  jobDetail: {
    loading: true,
    fetchingSuccessful: true
  },
  countryCode: 'ES',
  sortBy: 'start_date',
  sortDir: 'asc',
  regions: [],
  selectedRegions: [],
  jobFunctions: [],
  selectedJobFunctions: [],
  candidate: null,
  errors: {},
  mapUrlSignatureCache: {}
};

const jobfeedReducer = (state = JOBFEED_DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_JOB_LIST_REQUEST:
    case FETCH_REGIONS_REQUEST:
    case FETCH_JOB_FUNCTIONS_REQUEST: {
      return {
        ...state,
        jobList: {
          loading: true,
          fetchingSuccessful: true
        }
      };
    }
    case FETCH_JOB_LIST_SUCCESS: {
      const { jobOpportunities, jobOpportunity, totalNumber } = action.payload;

      return {
        ...state,
        jobOpportunities,
        jobOpportunity: jobOpportunity || state.jobOpportunity,
        totalNumber,
        jobList: {
          loading: false,
          firstLoad: false,
          fetchingSuccessful: true
        }
      };
    }
    case FETCH_JOB_LIST_FAILURE: {
      return {
        ...state,
        jobList: {
          loading: false,
          fetchingSuccessful: false
        }
      };
    }
    case FETCH_JOB_DETAIL_REQUEST: {
      return {
        ...state,
        jobDetail: {
          loading: true,
          fetchingSuccessful: true
        }
      };
    }
    case FETCH_JOB_DETAIL_SUCCESS: {
      const { jobOpportunity } = action.payload;
      return {
        ...state,
        jobOpportunity,
        jobDetail: {
          loading: false,
          fetchingSuccessful: true
        }
      };
    }
    case FETCH_JOB_DETAIL_FAILURE: {
      return {
        ...state,
        jobDetail: {
          loading: false,
          fetchingSuccessful: false
        }
      };
    }
    case FETCH_REGIONS_SUCCESS: {
      const { regions } = action.payload;
      return {
        ...state,
        regions,
        jobList: {
          loading: false,
          fetchingSuccessful: true
        }
      };
    }
    case FETCH_JOB_FUNCTIONS_SUCCESS: {
      const { jobFunctions } = action.payload;
      return {
        ...state,
        jobFunctions,
        jobList: {
          loading: false,
          fetchingSuccessful: true
        }
      };
    }
    case ADD_SELECTED_REGION: {
      const { region } = action.payload;
      const selectedRegions = [...state.selectedRegions, region];
      return {
        ...state,
        page: JOBFEED_DEFAULT_STATE.page,
        perPage: JOBFEED_DEFAULT_STATE.perPage,
        selectedRegions
      };
    }
    case DELETE_SELECTED_REGION: {
      const { region } = action.payload;
      const selectedRegions = state.selectedRegions.filter(item => item !== region);
      return {
        ...state,
        page: JOBFEED_DEFAULT_STATE.page,
        perPage: JOBFEED_DEFAULT_STATE.perPage,
        selectedRegions
      };
    }
    case UPDATE_SELECTED_REGIONS: {
      const { selectedRegions } = action.payload;
      return {
        ...state,
        selectedRegions
      };
    }
    case ADD_SELECTED_JOB_FUNCTION: {
      const { jobFunction } = action.payload;
      const selectedJobFunctions = [...state.selectedJobFunctions, jobFunction];
      return {
        ...state,
        page: JOBFEED_DEFAULT_STATE.page,
        perPage: JOBFEED_DEFAULT_STATE.perPage,
        selectedJobFunctions
      };
    }
    case DELETE_SELECTED_JOB_FUNCTION: {
      const { jobFunction } = action.payload;
      const selectedJobFunctions = state.selectedJobFunctions.filter(item => item !== jobFunction);
      return {
        ...state,
        page: JOBFEED_DEFAULT_STATE.page,
        perPage: JOBFEED_DEFAULT_STATE.perPage,
        selectedJobFunctions
      };
    }
    case UPDATE_SELECTED_JOB_FUNCTIONS: {
      const { selectedJobFunctions } = action.payload;
      return {
        ...state,
        selectedJobFunctions
      };
    }
    case RESET_FILTERS: {
      return {
        ...state,
        selectedRegions: [],
        selectedJobFunctions: [],
        page: JOBFEED_DEFAULT_STATE.page,
        perPage: JOBFEED_DEFAULT_STATE.perPage
      };
    }
    case CHANGE_COUNTRY: {
      const { countryCode } = action.payload;
      return {
        ...state,
        selectedRegions: [],
        selectedJobFunctions: [],
        page: JOBFEED_DEFAULT_STATE.page,
        perPage: JOBFEED_DEFAULT_STATE.perPage,
        countryCode
      };
    }
    case CHANGE_SORT: {
      const { sortBy, sortDir } = action.payload;

      return {
        ...state,
        sortBy,
        sortDir
      };
    }
    case LOCATION_CHANGE: {
      return state;
    }
    case FETCH_GEOLOCATION_MAP_URL_SIGNATURE_SUCCESS: {
      const { url, signature } = action.payload;
      return {
        ...state,
        mapUrlSignatureCache: {
          ...state.mapUrlSignatureCache,
          [url]: signature
        }
      };
    }
    case DISABLE_FOCUS_ON: {
      return {
        ...state,
        focusOn: false
      };
    }
    default:
      return state;
  }
};

export default jobfeedReducer;
