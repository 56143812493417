class CountriesService {
  listCountries() {
    return [
      { code: 'CO', name: 'colombia' },
      { code: 'ES', name: 'spain' },
      { code: 'SE', name: 'sweden' },
      { code: 'GB', name: 'great_britain' }
    ];
  }

  notSupportedCountries() {
    return [
      {
        code: 'SE',
        url: 'https://jobb.jobandtalent.com/'
      }
    ];
  }

  countryName(code) {
    const selectedCountry = this.listCountries().find(country => country.code === code);
    return selectedCountry ? selectedCountry?.name : 'spain';
  }

  getDefaultCountry() {
    return 'es';
  }
}

export default CountriesService;
