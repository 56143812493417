import ApiClient from 'Common/services/ApiClient';

class GeolocationService {
  endpoint = '/geolocation/map_url_signature';

  constructor() {
    this.client = new ApiClient({
      defaultHeaders: { 'X-Organization': 'Jobandtalent' }
    });
  }

  async createMapUrlSignature(url, csrfToken) {
    const response = await this.client.postFormData(`${this.endpoint}`, { url }, csrfToken);

    const json = await response.json();

    if (!response.ok) {
      throw json.error.value.message;
    }

    return json.signature;
  }
}

export default new GeolocationService();
