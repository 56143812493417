const getUTMData = () => {
  const searchParams = new URLSearchParams(window.location.search);

  return {
    utm_campaign: searchParams.has('utm_campaign') ? searchParams.get('utm_campaign') : '',
    utm_source: searchParams.has('utm_source') ? searchParams.get('utm_source') : '',
    utm_medium: searchParams.has('utm_medium') ? searchParams.get('utm_medium') : ''
  };
};

export const isMobile = function isMobile() {
  return global.window && ('ontouchstart' in window || navigator.maxTouchPoints > 0);
};

const getUTMSource = function getUTMSource() {
  return isMobile() ? 'web_mobile' : 'web_desktop';
};

export { getUTMData, getUTMSource };

export default getUTMData;
