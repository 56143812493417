import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Stylesheet
import './slate.component.css.less';

const Slate = ({ title, subtitle, svg, children, className }) => (
  <div className={classnames('Slate', className)} data-testid="slate">
    <div className="Slate__main-content">
      <img className="Slate__image" src={svg} alt={title} />
      <div className="Slate__title">{title}</div>
      <div className="Slate__subtitle">{subtitle}</div>
    </div>
    {children}
  </div>
);

Slate.propTypes = {
  title: PropTypes.node.isRequired,
  subtitle: PropTypes.string.isRequired,
  svg: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string
};

Slate.defaultProps = {
  children: null,
  className: ''
};

export default Slate;
