import linksCO from './links.es-CO';
import linksDE from './links.de-DE';
import linksES from './links.es-ES';
import linksFR from './links.fr-FR';
import linksGB from './links.en-GB';
import linksMX from './links.es-MX';
import linksPT from './links.pt-PT';
import linksSE from './links.sv-SE';

const getLinks = locale => {
  switch (locale) {
    case 'es-CO':
      return linksCO;
    case 'de-DE':
      return linksDE;
    case 'es-ES':
      return linksES;
    case 'fr-FR':
      return linksFR;
    case 'es-MX':
      return linksMX;
    case 'pt-PT':
      return linksPT;
    case 'sv-SE':
      return linksSE;
    default:
      return linksGB;
  }
};

export default getLinks;
