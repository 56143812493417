import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ValidatingInputText } from '@jobandtalent/design-system';

import eyeIcon from 'Common/images/eye-icon.svg';
import eyeIconStriken from 'Common/images/eye-icon-strike.svg';
import './password-input.component.css.less';
import classnames from 'classnames';

const PasswordInput = ({ handleOnChange, validation, className, inputProps, ...props }) => {
  const [revealPassword, setRevealPassword] = useState(false);

  const toggleRevealPassword = () => setRevealPassword(!revealPassword);

  return (
    <div className="GenericPassword">
      <ValidatingInputText
        type={revealPassword ? 'text' : 'password'}
        className={classnames('GenericPassword__input', { className })}
        onChange={handleOnChange}
        validation={validation}
        inputProps={inputProps}
        {...props}
      />
      <button
        onClick={toggleRevealPassword}
        type="button"
        className="GenericPassword__reveal-password-button"
        data-testid="reveal-password-button"
      >
        <img
          className="GenericPassword__eye-icon"
          src={revealPassword ? eyeIconStriken : eyeIcon}
          alt="reveal-password"
        />
      </button>
    </div>
  );
};

PasswordInput.propTypes = {
  handleOnChange: PropTypes.func.isRequired,
  validation: PropTypes.object,
  inputProps: PropTypes.object,
  className: PropTypes.string
};

PasswordInput.defaultProps = {
  validation: {},
  inputProps: {},
  className: ''
};

export default PasswordInput;
