import {
  FETCH_JOB_LIST_REQUEST,
  FETCH_JOB_LIST_SUCCESS,
  FETCH_JOB_LIST_FAILURE,
  FETCH_REGIONS_REQUEST,
  FETCH_REGIONS_SUCCESS,
  FETCH_REGIONS_FAILURE,
  FETCH_JOB_FUNCTIONS_REQUEST,
  FETCH_JOB_FUNCTIONS_SUCCESS,
  FETCH_JOB_FUNCTIONS_FAILURE,
  FETCH_JOB_DETAIL_REQUEST,
  FETCH_JOB_DETAIL_SUCCESS,
  FETCH_JOB_DETAIL_FAILURE
} from 'Jobfeed/store/actions_names';
import JobOpportunitiesService from 'Jobfeed/services/job-opening.service';
import JobOpportunity from 'Jobfeed/models/job-opening.model';

const fetchJobListRequest = () => ({
  type: FETCH_JOB_LIST_REQUEST
});

const fetchJobListSuccess = ({ jobOpportunities, jobOpportunity, totalNumber }) => ({
  type: FETCH_JOB_LIST_SUCCESS,
  payload: {
    jobOpportunities,
    jobOpportunity,
    totalNumber
  }
});

const fetchJobListFailure = () => ({
  type: FETCH_JOB_LIST_FAILURE
});

const fetchRegionsRequest = () => ({
  type: FETCH_REGIONS_REQUEST
});

const fetchRegionsSuccess = ({ regions }) => ({
  type: FETCH_REGIONS_SUCCESS,
  payload: {
    regions
  }
});

const fetchRegionsFailure = () => ({
  type: FETCH_REGIONS_FAILURE
});

const fetchJobFunctionsRequest = () => ({
  type: FETCH_JOB_FUNCTIONS_REQUEST
});

const fetchJobFunctionsSuccess = ({ jobFunctions }) => ({
  type: FETCH_JOB_FUNCTIONS_SUCCESS,
  payload: {
    jobFunctions
  }
});

const fetchJobFunctionsFailure = () => ({
  type: FETCH_JOB_FUNCTIONS_FAILURE
});

export const fetchJobList = (options = {}) => async (dispatch, getState) => {
  const {
    locale,
    page,
    perPage,
    countryCode,
    selectedRegions,
    selectedJobFunctions,
    sortBy,
    sortDir
  } = options;
  const { jobfeed } = getState();

  if (jobfeed.jobList.firstLoad) {
    dispatch(
      fetchJobListSuccess({
        jobOpportunities: jobfeed.jobOpportunities.map(jobOpportunity => {
          return new JobOpportunity(jobOpportunity);
        }),
        totalNumber: jobfeed.totalNumber
      })
    );
    return;
  }

  dispatch(fetchJobListRequest());
  try {
    const params = {
      locale,
      page,
      per_page: perPage,
      countryCode,
      sort_by: sortBy,
      sort_order: sortDir
    };

    if (selectedRegions?.length > 0) {
      params.regions = selectedRegions;
    }

    if (selectedJobFunctions?.length > 0) {
      params.job_functions = selectedJobFunctions;
    }

    const response = await JobOpportunitiesService.getJobOpportunities(params);
    const jobOpportunities = response.data.jobOpportunities
      .map(jobOpportunity => {
        return new JobOpportunity(jobOpportunity);
      })
      .filter(jobOpportunity => page === 1 || jobOpportunity.id !== jobfeed.jobOpportunity.id);

    dispatch(
      fetchJobListSuccess({
        jobOpportunities:
          page === 1 ? jobOpportunities : [...jobfeed.jobOpportunities, ...jobOpportunities],
        jobOpportunity: page === 1 ? jobOpportunities?.[0] : jobfeed.jobOpportunity,
        totalNumber: response.meta.totalCount
      })
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    dispatch(fetchJobListFailure());
  }
};

// Job Detail
const fetchJobDetailRequest = () => ({
  type: FETCH_JOB_DETAIL_REQUEST
});

export const fetchJobDetailSuccess = ({ jobOpportunity }) => ({
  type: FETCH_JOB_DETAIL_SUCCESS,
  payload: {
    jobOpportunity
  }
});

const fetchJobDetailFailure = () => ({
  type: FETCH_JOB_DETAIL_FAILURE
});

export const fetchJobDetail = job_opening_id => async dispatch => {
  dispatch(fetchJobDetailRequest());
  try {
    const response = await JobOpportunitiesService.getJobOpportunity(job_opening_id);

    dispatch(
      fetchJobDetailSuccess({
        jobOpportunity: new JobOpportunity(response.data.jobOpportunity)
      })
    );
  } catch (error) {
    dispatch(fetchJobDetailFailure());
  }
};

export const fetchRegions = (options = {}) => async dispatch => {
  const { countryCode } = options;
  dispatch(fetchRegionsRequest());
  try {
    const response = await JobOpportunitiesService.getRegions({ country_code: countryCode });

    dispatch(
      fetchRegionsSuccess({
        regions: response?.data?.regions || []
      })
    );
  } catch (error) {
    dispatch(fetchRegionsFailure);
  }
};

export const fetchJobFunctions = (options = {}) => async dispatch => {
  dispatch(fetchJobFunctionsRequest());
  try {
    const response = await JobOpportunitiesService.getJobFunctions(options);

    dispatch(
      fetchJobFunctionsSuccess({
        jobFunctions: response?.data?.categories
      })
    );
  } catch (error) {
    dispatch(fetchJobFunctionsFailure);
  }
};
