import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

// Stylesheets
import './modal.component.css.less';

export default class Modal extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    onHide: PropTypes.func,
    className: PropTypes.string,
    container: PropTypes.string
  };

  static defaultProps = {
    onHide: () => {},
    className: '',
    container: ''
  };

  constructor() {
    super();
    this.ssr = typeof window === 'undefined';
  }

  componentDidMount() {
    try {
      window.scrollTo(0, 0);
      document.body.style.overflow = 'hidden';
      document.addEventListener('keydown', this.handleKeyDown, false);
    } catch (e) {} // eslint-disable-line no-empty
  }

  componentWillUnmount() {
    try {
      document.body.removeAttribute('style');
      document.removeEventListener('keydown', this.handleKeyDown, false);
    } catch (e) {} // eslint-disable-line no-empty
  }

  handleKeyDown = event => {
    const isEsc = event.keyCode === 27;

    if (isEsc) {
      this.hideModal();
    }
  };

  handleOverlayClose = event => {
    if (this.props.className && this.props.className.includes('reset-password-modal')) return;
    try {
      const { target } = event;
      if (target.classList.contains('ds-org-modal-overlay')) {
        event.preventDefault();
        this.hideModal();
      }
    } catch (e) {
      this.hideModal();
    }
  };

  hideModal = event => {
    if (event) {
      event.stopPropagation();
    }
    this.props.onHide();
  };

  renderModal = () => {
    return (
      <div
        className={`ds-org-modal-overlay ${this.props.className}`}
        onClick={this.handleOverlayClose}
        onKeyDown={this.handleKeyDown}
        role="button"
        tabIndex={0}
        data-testid="modal-overlay"
      >
        <div data-testid="modal" className="modal" ref={this.modalContent}>
          <div className="notranslate">{this.props.children}</div>
        </div>
      </div>
    );
  };

  getContainer = () => {
    if (this.ssr) {
      return null;
    }

    return this.props.container ? document.querySelector(this.props.container) : document.body;
  };

  render() {
    const container = this.getContainer();
    const modal = this.renderModal();
    return this.ssr ? modal : ReactDOM.createPortal(modal, container);
  }
}
