import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

// Stylesheets
import './button.component.css.less';

const Button = ({ variant, size, className, onClick, children }) => {
  return (
    <button
      className={classnames('Button', className, {
        'Button--primary': variant === 'primary',
        'Button--tertiary': variant === 'tertiary',
        'Button--small': size === 'small'
      })}
      onClick={onClick}
      onKeyDown={event => {
        if (event.key === 'Enter') {
          onClick();
        }
      }}
      type="button"
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  variant: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node
};

Button.defaultProps = {
  variant: 'primary',
  size: 'normal',
  className: '',
  onClick: () => undefined,
  children: null
};

export default Button;
