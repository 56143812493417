import { push } from 'connected-react-router';
import {
  ADD_SELECTED_REGION,
  DELETE_SELECTED_REGION,
  UPDATE_SELECTED_REGIONS,
  UPDATE_SELECTED_JOB_FUNCTIONS,
  ADD_SELECTED_JOB_FUNCTION,
  DELETE_SELECTED_JOB_FUNCTION,
  RESET_FILTERS,
  CHANGE_COUNTRY,
  CHANGE_SORT
} from 'Jobfeed/store/actions_names';

// Filters
const buildQueryParams = args => {
  return Object.entries(args)
    .map(([key, value]) => {
      return value.length > 0 ? `${key}=${value}` : '';
    })
    .filter(param => param.length > 0)
    .join('&');
};

const buildJobFunctionsUrl = (regions, jobFunctions, baseUrl) => {
  const jobFunctionsUrl = [baseUrl, jobFunctions[0] || '']
    .filter(param => param.length > 0)
    .join('/');

  return regions.length > 0 ? baseUrl : jobFunctionsUrl;
};

// Update Urls for the selected regions and jobFunctions
// Returns:
// When only a region is selected: jobs/en/region-slug
// When only a jobFunction is selected: jobs/jobFunction-slug
// Other cases: Returns the region url with the first region and the other regions and jobFunctions as a params
// example: jobs/en/first-region-slug?regions=second-region-slug,third-region-slug&job-functions=first-jobFunction-slug
const updateUrl = () => async (dispatch, getState) => {
  const {
    jobfeed: { countryCode, selectedRegions, selectedJobFunctions, sortBy, sortDir }
  } = getState();

  const regionsUrl =
    selectedRegions.length > 0
      ? `/${countryCode.toLowerCase()}/${selectedRegions[0]}`
      : `/${countryCode.toLowerCase()}`;
  const params = buildQueryParams({
    regions: selectedRegions.slice(1),
    jobFunctions: selectedJobFunctions.slice(selectedRegions.length > 0 ? 0 : 1),
    sort_by: sortBy,
    sort_order: sortDir
  });

  const baseUrl = buildJobFunctionsUrl(selectedRegions, selectedJobFunctions, regionsUrl);
  const url = [baseUrl, params].filter(param => param.length > 0).join('?');
  dispatch(push(url));
};

const addSelectedRegion = ({ region }) => ({
  type: ADD_SELECTED_REGION,
  payload: {
    region
  }
});

const deleteSelectedRegion = ({ region }) => ({
  type: DELETE_SELECTED_REGION,
  payload: {
    region
  }
});

const updateSelectedRegions = ({ selectedRegions }) => ({
  type: UPDATE_SELECTED_REGIONS,
  payload: {
    selectedRegions
  }
});

// Select JobFunctions
const addSelectedJobFunction = ({ jobFunction }) => ({
  type: ADD_SELECTED_JOB_FUNCTION,
  payload: {
    jobFunction
  }
});

const deleteSelectedJobFunction = ({ jobFunction }) => ({
  type: DELETE_SELECTED_JOB_FUNCTION,
  payload: {
    jobFunction
  }
});

const updateSelectedJobFunctions = ({ selectedJobFunctions }) => ({
  type: UPDATE_SELECTED_JOB_FUNCTIONS,
  payload: {
    selectedJobFunctions
  }
});

const resetFilters = () => ({
  type: RESET_FILTERS
});

const changeCountry = ({ countryCode }) => ({
  type: CHANGE_COUNTRY,
  payload: {
    countryCode
  }
});

// eslint-disable-next-line import/prefer-default-export
export const updateFilter = ({ updateType, payload }) => async dispatch => {
  switch (updateType) {
    case ADD_SELECTED_REGION:
      dispatch(addSelectedRegion({ region: payload }));
      break;
    case DELETE_SELECTED_REGION:
      dispatch(deleteSelectedRegion({ region: payload }));
      break;
    case UPDATE_SELECTED_REGIONS:
      dispatch(updateSelectedRegions({ selectedRegions: payload }));
      break;
    case ADD_SELECTED_JOB_FUNCTION:
      dispatch(addSelectedJobFunction({ jobFunction: payload }));
      break;
    case DELETE_SELECTED_JOB_FUNCTION:
      dispatch(deleteSelectedJobFunction({ jobFunction: payload }));
      break;
    case UPDATE_SELECTED_JOB_FUNCTIONS:
      dispatch(updateSelectedJobFunctions({ selectedJobFunctions: payload }));
      break;
    case RESET_FILTERS:
      dispatch(resetFilters());
      break;
    case CHANGE_COUNTRY:
      dispatch(changeCountry({ countryCode: payload }));
      break;
    case CHANGE_SORT:
      dispatch({ type: CHANGE_SORT, payload });
      break;
    default:
      break;
  }
  dispatch(updateUrl());
};
