import React from 'react';
import PropTypes from 'prop-types';
import I18n from 'lib/i18n';
import { Translation } from 'Jobfeed/components/translation/translation.component';
import getLinks from './assets/getLinks';

// Stylesheets
import './footer.component.css.less';
import Dropdown from '../dropdown/dropdown';

const buildUrl = (host, href, relative) => (relative ? `${host}${href}` : href);

const Footer = ({ landingsUrl }) => {
  const { footerLinksData, legalLinksData, downloadLinksData } = getLinks(I18n.locale);

  return (
    <div className="Footer">
      <nav className="Footer__nav" data-testid="footer-columns">
        <ul className="Footer__links">
          {footerLinksData.map(linkObj => (
            <Translation key={`translation-${linkObj.key}`} name={`${linkObj.key}.href`}>
              {hrefTranslation => (
                <li key={linkObj.key} className="Footer__link-item">
                  <a
                    className="Footer__link"
                    data-testid="footer-link"
                    href={buildUrl(landingsUrl, hrefTranslation, linkObj.relative)}
                  >
                    {I18n.t(`${linkObj.key}.label`)}
                  </a>
                </li>
              )}
            </Translation>
          ))}
          <li className="Footer__link-item">
            <Dropdown triggerTitle={I18n.t(downloadLinksData.columnKey)}>
              <ul className="Footer__dropdown-links">
                {downloadLinksData.links.map(linkObj => (
                  <Translation key={`translation-${linkObj.key}`} name={`${linkObj.key}.href`}>
                    {hrefTranslation => (
                      <li key={linkObj.key} className="Footer__dropdown-link-item">
                        <a
                          href={hrefTranslation}
                          target="__blank"
                          rel="noreferrer noopener"
                          aria-label={`${I18n.t(`${linkObj.key}.label`)} (opens in new tab)`}
                          data-testid="footer-icon-link"
                          className="Footer__dropdown-link"
                        >
                          {I18n.t(`${linkObj.key}.label`)}
                        </a>
                      </li>
                    )}
                  </Translation>
                ))}
              </ul>
            </Dropdown>
          </li>
          <li className="Footer__link-item">
            <Dropdown triggerTitle={I18n.t(legalLinksData.columnKey)}>
              <ul className="Footer__dropdown-links">
                {legalLinksData.links.map(linkObj => (
                  <Translation key={`translation-${linkObj.key}`} name={`${linkObj.key}.href`}>
                    {hrefTranslation => (
                      <li key={linkObj.key} className="Footer__dropdown-link-item">
                        <a
                          className="Footer__dropdown-link"
                          data-testid="footer-link"
                          href={buildUrl(landingsUrl, hrefTranslation, linkObj.relative)}
                        >
                          {I18n.t(`${linkObj.key}.label`)}
                        </a>
                      </li>
                    )}
                  </Translation>
                ))}
              </ul>
            </Dropdown>
          </li>
        </ul>
      </nav>
      <div className="Footer__copyright-notice">{I18n.t('jobfeed.footer.copyright_notice')}</div>
    </div>
  );
};

Footer.propTypes = {
  landingsUrl: PropTypes.string
};

Footer.defaultProps = {
  landingsUrl: ''
};

export default Footer;
