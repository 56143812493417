import React, { useState } from 'react';
import PropTypes from 'prop-types';

export const JobOpportunityContext = React.createContext();

export const JobOpportunityContextProvider = ({ children }) => {
  const [isQRVisible, setIsQRVisible] = useState(false);
  const [isDownloadOnMobileVisible, setIsDownloadOnMobileVisible] = useState(false);
  const [deeplink, setDeeplink] = useState(null);

  const value = {
    isQRVisible,
    isDownloadOnMobileVisible,
    deeplink,
    showApplyNow: () => {
      setIsQRVisible(true);
    },
    hideApplyNow: () => {
      setIsQRVisible(false);
    },
    showDownloadOnMobile: () => {
      setIsDownloadOnMobileVisible(true);
    },
    hideDownloadOnMobile: () => {
      setIsDownloadOnMobileVisible(false);
    },
    setDeeplink: link => {
      setDeeplink(link);
    }
  };

  return <JobOpportunityContext.Provider value={value}>{children}</JobOpportunityContext.Provider>;
};

JobOpportunityContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};
