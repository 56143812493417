import React from 'react';
import { track } from '@amplitude/analytics-browser';
import i18n from 'lib/i18n';
import { useStore } from 'Jobfeed/store/hook';
import { FiltersContext } from 'Jobfeed/context/FiltersContext';
import closeIcon from 'Common/images/close.svg';
import DropdownFilter from '../dropdown-filter/dropdown-filter.component';

const CategoryFilter = () => {
  const filtersContext = React.useContext(FiltersContext);
  const store = useStore();
  const categories = store.getJobFunctions();
  const selectedCategories = store.getSelectedJobFunctions();
  const countryCode = store.getCountryCode();
  const selectedRegions = store.getSelectedRegions();

  const items = categories
    .sort((a, b) => {
      return a.name.normalize('NFD') < b.name.normalize('NFD') ? -1 : 1;
    })
    .map(({ name, slug }) => {
      return { value: slug, label: name };
    });

  const onApply = slugs => {
    store.updateSelectedJobFunctions(slugs);

    track('filters_applied', {
      action: 'apply',
      number_categories: slugs.length,
      number_regions: selectedRegions.length,
      filter_location_country_values: countryCode,
      filter_location_region_values: selectedRegions,
      filter_category_values: slugs
    });

    filtersContext.closeFilter();
  };

  return selectedCategories.length ? (
    selectedCategories.map(category => (
      <DropdownFilter
        triggerText={categories.find(c => c.slug === category).name}
        key={category}
        heading={i18n.t('jobfeed.filters.choose_categories')}
        name={`categories-${category}`}
        items={items}
        selectedItem={category}
        preselectedItems={selectedCategories}
        onApply={onApply}
        headingAction={
          <button
            type="button"
            onClick={() => filtersContext.closeFilter()}
            className="DropdownFilter__heading-action DropdownFilter__heading-action--mobile"
          >
            <img className="DropdownFilter__trigger-icon" src={closeIcon} alt="" />
          </button>
        }
        includeTextSearch
      />
    ))
  ) : (
    <DropdownFilter
      triggerText={i18n.t('jobfeed.filters.job_functions')}
      heading={i18n.t('jobfeed.filters.choose_categories')}
      name="categories"
      items={items}
      preselectedItems={selectedCategories}
      onApply={onApply}
      headingAction={
        <button
          type="button"
          onClick={() => filtersContext.closeFilter()}
          className="DropdownFilter__heading-action DropdownFilter__heading-action--mobile"
        >
          <img className="DropdownFilter__trigger-icon" src={closeIcon} alt="" />
        </button>
      }
      includeTextSearch
    />
  );
};

export default CategoryFilter;
