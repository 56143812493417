import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

// Stylesheets
import './dropdown-filter-single-option.component.css.less';

const DropdownFilterSingleOption = ({ text, value, name, checked, onChange, className }) => {
  return (
    <label
      className={classnames('DropdownFilterSingleOption', className, {
        'DropdownFilterSingleOption--checked': checked
      })}
    >
      <span className="DropdownFilterSingleOption__label">{text}</span>
      <input checked={checked} type="radio" value={value} name={name} onChange={onChange} />
    </label>
  );
};

DropdownFilterSingleOption.propTypes = {
  text: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string
};

DropdownFilterSingleOption.defaultProps = {
  text: '',
  value: '',
  name: 'checkbox',
  checked: false,
  onChange: () => undefined,
  className: ''
};

export default DropdownFilterSingleOption;
