import ApiClient from 'Common/services/ApiClient';
import I18n from 'lib/i18n';

class SessionsService {
  endpoint = '/session';

  constructor() {
    this.client = new ApiClient({
      defaultHeaders: { 'X-Organization': 'Jobandtalent' }
    });
  }

  async submitPhoneNumber({ countryTelephoneCode, phone }, csrfToken) {
    const response = await this.client.postFormData(
      `/phone_verification`,
      {
        country_calling_code: countryTelephoneCode,
        subscriber_number: phone
      },
      csrfToken
    );
    const json = await response.json();
    if (!response.ok) {
      return json.error;
    }
    return json.data;
  }

  async resendPhoneNumber(verificationId, csrfToken) {
    const response = await this.client.postFormData(
      `/phone_verification/resend`,
      {
        verification_id: verificationId
      },
      csrfToken
    );
    const json = await response.json();
    if (!response.ok) {
      return json.error;
    }
    return json.data;
  }

  async validatePhone({ code, id }, csrfToken) {
    const response = await this.client.postFormData(
      `/phone_verification/verify`,
      {
        verification_id: id,
        token: code
      },
      csrfToken
    );
    const json = await response.json();
    if (!response.ok) {
      return json.error;
    }
    return json.data;
  }

  async resetPassword(params, csrfToken) {
    const { token, password, repeatPassword, email, source } = params;
    const response = await this.client.putFormData(
      '/reset_password',
      {
        reset_password_token: token,
        password,
        password_confirmation: repeatPassword,
        email,
        source
      },
      csrfToken
    );
    const json = await response.json();

    if (!response.ok) {
      const errorMsgDiv = document.querySelector('[data-error-msg]');
      errorMsgDiv.textContent = I18n.t('jobfeed.password_reset.error_msg');
      throw json.error;
    }
    return json.data;
  }

  async updateLocation(params, csrfToken) {
    const { rawLocation } = params;

    const response = await this.client.putFormData(
      '/users',
      {
        raw_location: rawLocation
      },
      csrfToken
    );
    const json = await response.json();

    if (!response.ok) {
      throw json.error;
    }
    return json.data;
  }

  async forgotPassword(params, csrfToken) {
    const { email, redirect } = params;
    const response = await this.client.postFormData(
      '/reset_password',
      {
        email,
        url: redirect
      },
      csrfToken
    );

    const json = await response.json();

    if (!response.ok) {
      throw json.error;
    }

    return json.data;
  }
}

export default new SessionsService();
