class DeeplinksService {
  appleStore() {
    return 'https://jobandtalent.onelink.me/2003021252?pid=web_mobile&c=itunesbutton&af_dp=jobandtalent%3A%2F%2F%2F&af_web_dp=https%3A%2F%2Fitunes.apple.com%2Fgb%2Fapp%2Fjobandtalent-job-search%2Fid665060895%3Fmt%3D8';
  }

  playStore() {
    return 'https://jobandtalent.onelink.me/2003021252?pid=web_mobile&c=google_play_Button&af_dp=jobandtalent%3A%2F%2F%2F&af_web_dp=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.jobandtalent.android%26hl%3Den';
  }

  genericAppStore() {
    return 'https://link.jobandtalent.com/ZCg5';
  }

  qrCodeDeeplink() {
    return 'https://open.jobandtalent.com';
  }
}

export default DeeplinksService;
