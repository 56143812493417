import classnames from 'classnames';
import React from 'react';
import { track } from '@amplitude/analytics-browser';
import PropTypes from 'prop-types';
import chevronIcon from 'Common/images/chevron.svg';
import closeCircleIcon from 'Common/images/close-circle.svg';

// Stylesheets
import './dropdown-filter.component.css.less';
import { FiltersContext } from 'Jobfeed/context/FiltersContext';
import { isMobile } from 'Jobfeed/services/tracking.service';
import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  shift,
  useDismiss,
  useFloating,
  useInteractions,
  useRole
} from '@floating-ui/react';
import DropdownFilterContent from './dropdown-filter-content';

const DropdownFilter = ({
  className,
  heading,
  headingAction,
  TriggerElement,
  triggerText,
  placeholder,
  name,
  items,
  selectedItem,
  preselectedItems,
  onApply,
  disableClearAll,
  disableSubmit,
  selectAllLabel,
  multi,
  simple,
  includeTextSearch
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const filtersContext = React.useContext(FiltersContext);
  const [selectedItems, setSelectedItems] = React.useState(preselectedItems);

  const toggleFilter = () => {
    if (isOpen) {
      filtersContext.closeFilter(name);
    } else {
      filtersContext.openFilter(name);

      track('filters_opened', {
        type: name === 'categories' ? 'category' : name // ["country","region","category"]
      });
    }
  };

  React.useEffect(() => {
    setIsOpen(filtersContext?.openedFilterId === name);
  }, [filtersContext?.openedFilterId]);

  React.useEffect(() => {
    setSelectedItems(preselectedItems);
  }, [preselectedItems, isOpen]);

  const onChange = event => {
    if (event.target.checked) {
      if (multi) {
        setSelectedItems([...selectedItems, event.target.value]);
      } else {
        setSelectedItems([event.target.value]);

        if (disableSubmit) {
          onApply(event.target.value);
        }
      }
    } else {
      setSelectedItems(selectedItems.filter(item => item !== event.target.value));
    }
  };

  const removeSelection = event => {
    event.stopPropagation();
    setSelectedItems(selectedItems.filter(item => item !== selectedItem));
    onApply(selectedItems.filter(item => item !== selectedItem));
  };

  const clearAll = () => {
    setSelectedItems([]);
  };

  const onSelectAll = () => {
    setSelectedItems([]);
  };

  const { x, y, refs, strategy, context } = useFloating({
    placement: 'bottom-start',
    open: isOpen,
    onOpenChange: toggleFilter,
    middleware: [
      offset({
        mainAxis: simple ? 8 : 32
      }),
      flip(),
      shift({ padding: 8 })
    ],
    whileElementsMounted: autoUpdate
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useRole(context, { role: 'tooltip' }),
    useDismiss(context, {
      enabled: global.window !== undefined ? !isMobile() : false
    })
  ]);

  return (
    <div className={classnames('DropdownFilter', className)}>
      {TriggerElement ? (
        <TriggerElement onClick={toggleFilter} ref={refs.setReference} {...getReferenceProps()} />
      ) : (
        <button
          className={classnames('DropdownFilter__trigger', {
            'DropdownFilter__trigger--open': isOpen,
            'DropdownFilter__trigger--selected': selectedItems.length > 0
          })}
          onClick={toggleFilter}
          type="button"
          ref={refs.setReference}
          {...getReferenceProps()}
        >
          <span>{triggerText}</span>
          {selectedItem ? (
            <span
              onClick={removeSelection}
              onKeyDown={event => {
                if (event.key === 'Enter') {
                  removeSelection();
                }
              }}
              tabIndex={0}
              role="button"
            >
              <img className="DropdownFilter__trigger-icon" src={closeCircleIcon} alt="" />
            </span>
          ) : (
            <img className="DropdownFilter__trigger-icon" src={chevronIcon} alt="" />
          )}
        </button>
      )}
      {isOpen && !isMobile() ? (
        <FloatingPortal>
          <div
            className={classnames('DropdownFilter__content', {
              'DropdownFilter__content--open': isOpen,
              'DropdownFilter__content--simple': simple
            })}
            ref={refs.setFloating}
            style={{
              position: strategy,
              zIndex: 10,
              top: y ?? 0,
              left: x ?? 0
            }}
            {...getFloatingProps()}
          >
            <DropdownFilterContent
              heading={heading}
              placeholder={placeholder}
              headingAction={headingAction}
              selectAllLabel={selectAllLabel}
              selectedItems={selectedItems}
              name={name}
              onSelectAll={onSelectAll}
              items={items}
              multi={multi}
              onChange={onChange}
              disableSubmit={disableSubmit}
              disableClearAll={disableClearAll}
              clearAll={clearAll}
              onApply={onApply}
              includeTextSearch={includeTextSearch}
            />
          </div>
        </FloatingPortal>
      ) : (
        <div
          className={classnames('DropdownFilter__content', {
            'DropdownFilter__content--open': isOpen,
            'DropdownFilter__content--simple': simple
          })}
        >
          <DropdownFilterContent
            heading={heading}
            placeholder={placeholder}
            headingAction={headingAction}
            selectAllLabel={selectAllLabel}
            selectedItems={selectedItems}
            name={name}
            onSelectAll={onSelectAll}
            items={items}
            multi={multi}
            onChange={onChange}
            disableSubmit={disableSubmit}
            disableClearAll={disableClearAll}
            clearAll={clearAll}
            onApply={onApply}
            includeTextSearch={includeTextSearch}
          />
        </div>
      )}
    </div>
  );
};

DropdownFilter.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string,
  placeholder: PropTypes.string,
  TriggerElement: PropTypes.object,
  triggerText: PropTypes.string,
  name: PropTypes.string,
  onApply: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ),
  selectedItem: PropTypes.string,
  preselectedItems: PropTypes.arrayOf(PropTypes.string),
  selectAllLabel: PropTypes.string,
  disableClearAll: PropTypes.bool,
  disableSubmit: PropTypes.bool,
  multi: PropTypes.bool,
  headingAction: PropTypes.node,
  simple: PropTypes.bool,
  includeTextSearch: PropTypes.bool
};

DropdownFilter.defaultProps = {
  className: '',
  heading: '',
  placeholder: '',
  TriggerElement: null,
  triggerText: '',
  name: 'dropdown',
  onApply: () => undefined,
  items: [],
  selectedItem: null,
  preselectedItems: [],
  selectAllLabel: null,
  disableClearAll: false,
  disableSubmit: false,
  multi: true,
  headingAction: null,
  simple: false,
  includeTextSearch: false
};

export default DropdownFilter;
