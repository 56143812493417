import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { track } from '@amplitude/analytics-browser';
import classnames from 'classnames';

// Containers
import JobList from 'Jobfeed/containers/job-list/job-list.component';

// Stylesheets
import './job-list.component.css.less';
import { useStore } from 'Jobfeed/store/hook';
import { GlobalContext } from 'Jobfeed/context/GlobalContext';
import JobDetailView from '../job-detail/job-detail.component';

const JobListView = ({ hasJOSelected, jobOpportunity }) => {
  const store = useStore();
  const globalContext = React.useContext(GlobalContext);
  const jobOpportunities = store.getJobOpportunities();

  useEffect(() => {
    track('job_feed_viewed', {
      sort_by: 'newest',
      is_default_sort: true
    });
  }, []);

  useEffect(() => {
    globalContext.setHasJOSelected(hasJOSelected);
  }, [hasJOSelected]);

  return (
    <div
      className={classnames('JobListView', {
        'JobListView--has-jo-selected': hasJOSelected
      })}
    >
      <div className="JobListView__wrapper">
        <JobList jobOpportunity={jobOpportunity} />
        {jobOpportunities.length ? <JobDetailView jobOpportunity={jobOpportunity} /> : null}
      </div>
    </div>
  );
};

JobListView.propTypes = {
  hasJOSelected: PropTypes.bool,
  jobOpportunity: PropTypes.object
};

JobListView.defaultProps = {
  hasJOSelected: false,
  jobOpportunity: {}
};

export default JobListView;
