import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useStore } from 'Jobfeed/store/hook';

// Views
import JobListView from 'Jobfeed/views/job-list/job-list.component';

// Paths
import CountriesService from 'Common/services/countries.service';
import usePaths from './paths';

const JobfeedRoutes = () => {
  const countriesService = new CountriesService();
  const notSupportedCountries = countriesService.notSupportedCountries();

  const paths = usePaths();
  const store = useStore();
  const perPage = store.getPerPage();
  const sortBy = store.getSortBy();
  const sortDir = store.getSortDir();
  const countryCode = store.getCountryCode();
  const selectedRegions = store.getSelectedRegions();
  const selectedJobFunctions = store.getSelectedJobFunctions();
  const jobOpportunities = store.getJobOpportunities();
  const jobOpportunity = store.getJobOpportunity();

  useEffect(() => {
    const newCountrySupported = !notSupportedCountries.some(
      country => country.code === countryCode
    );

    if (newCountrySupported) {
      store.fetchJobList({
        page: 1,
        perPage,
        countryCode,
        selectedRegions,
        selectedJobFunctions,
        sortBy,
        sortDir
      });
    }
  }, [selectedJobFunctions, sortBy, sortDir, selectedRegions]);

  useEffect(() => store.fetchJobFunctions({ countryCode }), [countryCode]);
  useEffect(() => store.fetchRegions({ countryCode }), [countryCode]);

  return (
    <>
      <Route
        path={paths.job()}
        exact
        render={props => {
          // eslint-disable-next-line react/prop-types
          const slug = props.match.params.jobSlug;

          return (
            <JobListView
              {...props}
              jobOpportunity={jobOpportunities.find(jo => jo.slug === slug)}
              hasJOSelected
            />
          );
        }}
      />
      <Route
        path={paths.jobs()}
        exact
        render={props => {
          return <JobListView {...props} jobOpportunity={jobOpportunity} />;
        }}
      />
    </>
  );
};

JobfeedRoutes.propTypes = {};

export default JobfeedRoutes;
